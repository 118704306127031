import { useCallback } from "react";
import {
  useNotify,
  useRefresh,
  useRedirect,
  SaveButton,
  Toolbar,
  useUpdate,
  useRecordContext,
} from "react-admin";
import {
  DownloadReportFileButton,
  HistoryBackButton,
} from "../../../lib/components";

import { BidsComparisonCalc } from "./types";
import {
  PermissionBidsComparison,
  RESOURCE_NAME_BIDS_COMPARISON,
} from "../constants";

const SaveBidComparisonButton = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const record = useRecordContext<BidsComparisonCalc>(props);
  const [saveBidComparison] = useUpdate(
    RESOURCE_NAME_BIDS_COMPARISON,
    record?.id
  );
  const refresh = useRefresh();

  const handleSave = useCallback(
    async (values) => {
      const {
        enquiry,
        excluded,
        summary,
        totals,
        vendorAccounts,
        bidIds,
        ...rest
      } = values;

      saveBidComparison(
        {
          payload: {
            data: {
              ...rest,
            },
          },
        },
        {
          onSuccess: () => {
            notify("resources.bidsComparison.messages.edited", {
              type: "success",
            });
            redirect(`/bids-comparison/${record?.id}`);
            refresh(true);
          },
          onFailure: () => {
            notify("resources.bidsComparison.messages.error", {
              type: "error",
            });
            redirect(`/bids-comparison/${record?.id}`);
            refresh(true);
          },
        }
      );
    },
    // eslint-disable-next-line
    [saveBidComparison, notify, redirect]
  );
  return <SaveButton {...props} onSave={handleSave} />;
};

const CustomBidComparisonToolbar = (props) => {
  const record = useRecordContext<BidsComparisonCalc>(props);
  const hasData = record?.summary?.length;
  const isReadOnly = props?.isReadOnly;
  return (
    <Toolbar
      {...props}
      style={{
        display: "inline-grid",
        gridAutoFlow: "column",
        gridColumnGap: 50,
        justifyContent: "start",
        alignItems: "center",
        marginLeft: 50,
        backgroundColor: "unset",
      }}
    >
      {hasData && !isReadOnly && <SaveBidComparisonButton {...props} />}
      {hasData && (
        <DownloadReportFileButton
          downloadPath={`/bids-comparison/download/excel/${props?.id}`}
          downloadPermissionName={PermissionBidsComparison.SHOW_BIDS_COMPARISON}
          tooltip="components.buttons.DownloadReportFileButton.tooltip"
        />
      )}
      <HistoryBackButton />
    </Toolbar>
  );
};

export { CustomBidComparisonToolbar };
