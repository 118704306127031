import { ReactElement, useMemo } from "react";
import {
  DefaultCrudListRowActions,
  ListRowActions,
  ListRowActionsProps,
  RedirectListRowAction,
} from "../../../lib/components";
import { linkToRecord, Record, useListFilterContext } from "react-admin";
import { PermissionEnquiries } from "../constants";
import { bidResource, PermissionBids, RESOURCE_NAME_BID } from "../../bids";
import {
  bidsComparisonResource,
  PermissionBidsComparison,
  RESOURCE_NAME_BIDS_COMPARISON,
} from "../../bidsComparison";
import { usePermissionsCheck } from "../../../lib/core";
import {
  ApprovalListRowActionButtons,
  FILTER_DIRECTION,
  PermissionBehavior,
  ProcEntityRecordStatus,
  ProcureApprovalStatus,
} from "../../../common";
import { parseISO, isAfter, isBefore } from "date-fns";

const EnquiryListRowActionButtons = (props: ListRowActionsProps) => {
  const { hasPermissionAccess, hasOwnOrAccountAccess, permissionsLoaded } =
    usePermissionsCheck();
  const context = useListFilterContext();
  const directionFilterValue = context?.filterValues?.direction;
  const isDirectionReceived =
    directionFilterValue === FILTER_DIRECTION.RECEIVED;
  const isVendorUser =
    isDirectionReceived && hasPermissionAccess(PermissionBehavior.CAN_SELL);

  const overriddenListRowActions: Array<ReactElement> = useMemo(() => {
    const keyPrefix = props?.record?.id;
    const record = props?.record;

    const status = record?.procureStatus;
    const procureApprovalStatus = record?.procureApprovalStatus;

    const canBid = record?.canBid;

    const accountId = isVendorUser
      ? record?.vendors?.length
        ? record?.vendors[0]?.vendorAccountId
        : null
      : record?.procureAccountId;
    const userId = isVendorUser ? null : record?.procureOwnerUserId;
    const hasShowEnquiryAccess =
      permissionsLoaded &&
      hasOwnOrAccountAccess(
        PermissionEnquiries.SHOW_ANY_ENQUIRY,
        PermissionEnquiries.SHOW_OWN_ENQUIRY,
        accountId,
        userId
      );

    const hasEditEnquiryAccess =
      !isVendorUser &&
      permissionsLoaded &&
      hasOwnOrAccountAccess(
        PermissionEnquiries.EDIT_ANY_ENQUIRY,
        PermissionEnquiries.EDIT_OWN_ENQUIRY,
        record?.procureAccountId,
        record?.procureOwnerUserId
      );

    const hasDeleteEnquiryAccess =
      permissionsLoaded &&
      hasOwnOrAccountAccess(
        PermissionEnquiries.DELETE_ANY_ENQUIRY,
        PermissionEnquiries.DELETE_OWN_ENQUIRY,
        record?.procureAccountId,
        record?.procureOwnerUserId
      );

    const isStatusWaitingDraft = status === ProcEntityRecordStatus.DRAFT;
    const isStatusWaitingForApproval =
      status === ProcEntityRecordStatus.WAITING_FOR_APPROVAL;
    const isStatusApproved = status === ProcEntityRecordStatus.APPROVED;
    const isStatusRejected = status === ProcEntityRecordStatus.REJECTED;
    const isStatusOnHold = status === ProcEntityRecordStatus.ON_HOLD;
    const isStatusInProgress = status === ProcEntityRecordStatus.IN_PROGRESS;
    const isStatusCompleted = status === ProcEntityRecordStatus.COMPLETED;
    const isStatusCanceled = status === ProcEntityRecordStatus.CANCELLED;

    const isRecordStatusModifiable =
      isStatusWaitingDraft ||
      isStatusWaitingForApproval ||
      isStatusApproved ||
      isStatusRejected ||
      isStatusOnHold;
    const isApprovalStatusApproved =
      procureApprovalStatus === ProcureApprovalStatus.APPROVED;

    const hasCreateBidPermission = hasPermissionAccess(
      PermissionBids.CREATE_BID
    );
    const hasCreateBidComparisonPermission = hasPermissionAccess(
      PermissionBidsComparison.CREATE_BIDS_COMPARISON
    );

    const shouldDisplayShowAction = async (
      record?: Record
    ): Promise<boolean> => {
      const shouldDisplay = hasShowEnquiryAccess;
      return Promise.resolve(shouldDisplay);
    };

    const shouldDisplayEditAction = async (
      record?: Record
    ): Promise<boolean> => {
      const shouldDisplay = isStatusWaitingDraft && hasEditEnquiryAccess;
      return Promise.resolve(shouldDisplay);
    };

    const shouldDisplayDeleteAction = async (
      record?: Record
    ): Promise<boolean> => {
      const shouldDisplay = isStatusWaitingDraft && hasDeleteEnquiryAccess;
      return Promise.resolve(shouldDisplay);
    };

    const calculateCreateBidActionRedirectPath = (record?: Record) => {
      const createBidPath = record
        ? `${RESOURCE_NAME_BID}/create?enquiryId=${record.id}`
        : "";

      return createBidPath;
    };

    const shouldDisplayCreateBidAction = async (
      record?: Record
    ): Promise<boolean> => {
      const bidStartDateStr = record?.bidStartDate;
      const bidStartDate = parseISO(bidStartDateStr);
      const bidEndDateStr = record?.bidEndDate;
      const bidEndDate = parseISO(bidEndDateStr);
      const today = new Date();
      const areBidDatesValid =
        isAfter(today, bidStartDate) && isBefore(today, bidEndDate);

      //const shouldDisplay = hasCreateBidPermission && isStatusApproved && canBid;
      const shouldDisplay =
        isVendorUser &&
        hasCreateBidPermission &&
        canBid &&
        areBidDatesValid &&
        (isStatusApproved || isStatusInProgress) &&
        isApprovalStatusApproved;
      return Promise.resolve(shouldDisplay);
    };

    const calculateBidsComparisonRedirectPath = (record?: Record) => {
      const bidsComparisonPath = record
        ? linkToRecord(`/${RESOURCE_NAME_BIDS_COMPARISON}`, record?.id)
        : "";

      return bidsComparisonPath;
    };

    const shouldDisplayBidsComparisonAction = (record?: Record) => {
      //const shouldShow = hasCreateBidComparisonPermission && (isStatusInProgress || isStatusCompleted);
      const shouldShow = hasCreateBidComparisonPermission && !isStatusCanceled;

      return Promise.resolve(shouldShow);
    };

    const listRowActions: Array<ReactElement> = [
      <DefaultCrudListRowActions
        key={`defaultCrudListRowActions-${keyPrefix}`}
        shouldDisplayShow={shouldDisplayShowAction}
        shouldDisplayEdit={shouldDisplayEditAction}
        shouldDisplayDelete={shouldDisplayDeleteAction}
        hasEdit={hasEditEnquiryAccess}
        hasShow={hasShowEnquiryAccess}
        {...props}
      />,
    ];

    if (!isVendorUser) {
      listRowActions.push(<ApprovalListRowActionButtons {...props} />);
    }

    listRowActions.push(
      <RedirectListRowAction
        tooltipTitle="common.actions.viewBidComparison"
        icon={<bidsComparisonResource.icon />}
        calculateRedirectPath={calculateBidsComparisonRedirectPath}
        shouldDisplay={shouldDisplayBidsComparisonAction}
      />
    );

    listRowActions.push(
      <RedirectListRowAction
        key={`create.requisition.action-${keyPrefix}`}
        tooltipTitle="resources.enquiries.actions.createBid"
        icon={<bidResource.icon />}
        calculateRedirectPath={calculateCreateBidActionRedirectPath}
        shouldDisplay={shouldDisplayCreateBidAction}
        {...props}
      />
    );

    return listRowActions;
  }, [
    hasOwnOrAccountAccess,
    hasPermissionAccess,
    isVendorUser,
    permissionsLoaded,
    props,
  ]);

  return (
    <ListRowActions
      overriddenListRowActions={overriddenListRowActions}
      {...props}
    />
  );
};

export { EnquiryListRowActionButtons };
