import { Box } from "@material-ui/core";
import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  Loading,
  ReferenceField,
  ResourceContextProvider,
  TextField,
  useRecordContext,
} from "react-admin";
import { ApprovalStatusField, FILTER_DIRECTION, ProcureAccountField, RecordStatusField, UserNameEmailField } from "../../../common";
import { SectionTitleSubtitle } from "../../../lib/components";
import { COMMON_DATE_TIME_FORMAT } from "../../../lib/core";
import { RESOURCE_NAME_ACCOUNT } from "../../../lib/modules";
import { RESOURCE_NAME_BUSINESS_FUNCTIONS } from "../../datasetup/business-functions/constants";
import { RESOURCE_NAME_ENQUIRY } from "../../enquiries";
import { EnquiryShowContainer } from "../../enquiries/components";

const RequisitionShowEnquiries = (props) => {
  const record = useRecordContext(props);
  const requisitionId = record?.id;

  if (!requisitionId) {
    return <Loading />
  }
  return (
    <Box>
      <SectionTitleSubtitle
        title="resources.requisitions.fieldGroups.requisitionEnquiries.title"
        subTitle="resources.requisitions.fieldGroups.requisitionEnquiries.subTitle"
      />
      <ResourceContextProvider value={RESOURCE_NAME_ENQUIRY}>
        <List
          basePath={`/${RESOURCE_NAME_ENQUIRY}`}
          filter={{
            requisitionId,
            direction: FILTER_DIRECTION.SENT
          }}
          perPage={5}
          sort={{ field: "id", order: "ASC" }}
          title="resources.enquiries.sections.bids.field.title"
          actions={false}
          syncWithLocation={false}
          bulkActionButtons={false}
        >
          <Datagrid optimized expand={<EnquiryShowContainer />}>
            <TextField source="code" />
            <ReferenceField
              source="accountId"
              sortable={false}
              reference={RESOURCE_NAME_ACCOUNT}
            >
              <TextField source="displayLabel" />
            </ReferenceField>
            <ReferenceField
              source="name"
              reference={RESOURCE_NAME_BUSINESS_FUNCTIONS}
              sortable={false}
            >
              <TextField source="displayLabel" />
            </ReferenceField>

            <DateField source="bidStartDate" options={COMMON_DATE_TIME_FORMAT} />
            <DateField source="bidEndDate" options={COMMON_DATE_TIME_FORMAT} />
            <DateField
              source="preferredDeliveryDate"
              options={COMMON_DATE_TIME_FORMAT}
            />
            <RecordStatusField label="common.fields.status" />
            <ApprovalStatusField label="common.fields.approvalStatus" />
            <ProcureAccountField label={'common.fields.procureAccountId'} />
            <UserNameEmailField source="procureOwnerUserId" label={'common.fields.procureOwnerUserId'} />
            <BooleanField source="canLevel1Approve" />
            <BooleanField source="canLevel2Approve" />
            <DateField
              source="createdAt"
              showTime
              options={COMMON_DATE_TIME_FORMAT}
            />
            <DateField
              source="updatedAt"
              showTime
              options={COMMON_DATE_TIME_FORMAT}
            />
            <TextField source="id" />
            <TextField source="version" />
            <BooleanField source="isActive" />
          </Datagrid>
        </List>
      </ResourceContextProvider>
    </Box>
  );
};

export { RequisitionShowEnquiries };
