// import { SVGProps } from "react";
// import { useTheme } from "@material-ui/core/styles";

const Logo = (props) => (
  <img src="/logo.png" style={{ width: "320px", height: "30.5px" }} />
);

/* const Logo = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg width={350} height={20.475} viewBox="0 0 114.32 5.7785" {...props}>
      <g
        aria-label={process.env.REACT_APP_HEADER_LABEL}
        style={{
          lineHeight: 1.25,
        }}
        fontWeight={400}
        fontSize={7.056}
        fontFamily="Permanent Marker"
        letterSpacing={0}
        wordSpacing={0}
        strokeWidth={0.265}
        fill={theme.palette.secondary.light}
        transform="translate(82.237 -57.186)"
      >
        <g transform="translate(-111.79 -13.406)">
          <g id="a">
            <g aria-label={process.env.REACT_APP_HEADER_LABEL}>
              <path
                d="m30.575 73.605-0.20002 1.6256h-0.81915l0.5588-4.591h1.397q0.39052 0 0.66992 0.09525 0.28258 0.09208 0.46355 0.26035 0.18098 0.16828 0.2667 0.40005 0.0889 0.2286 0.0889 0.50482 0 0.3683-0.11748 0.68262-0.1143 0.31115-0.34608 0.53975-0.23178 0.22542-0.58102 0.3556-0.34608 0.127-0.80962 0.127zm0.28575-2.3304-0.20638 1.6891h0.57468q0.24448 0 0.42228-0.07303t0.2921-0.2032q0.11748-0.13335 0.17145-0.31432 0.05715-0.18098 0.05715-0.40005 0-0.15558-0.04445-0.28575t-0.13652-0.21908q-0.09208-0.09207-0.23178-0.14288-0.13652-0.0508-0.32702-0.0508z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m33.877 75.231 0.3937-3.2607h0.40005q0.12382 0 0.1905 0.06032 0.06985 0.06033 0.06985 0.19368l-0.02222 0.53022q0.12382-0.2413 0.26352-0.41275 0.14288-0.17462 0.29528-0.26988 0.1524-0.09842 0.31115-0.1143 0.16192-0.01905 0.32702 0.0508l-0.14287 0.7493q-0.39052-0.16192-0.68262 0.08255-0.28892 0.2413-0.46355 0.9525l-0.17145 1.4383z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m38.179 74.675q0.19685 0 0.3556-0.11112 0.15875-0.1143 0.26988-0.30162 0.11112-0.1905 0.17145-0.44132 0.06032-0.25082 0.06032-0.52705 0-0.39688-0.13335-0.58102-0.13335-0.18732-0.4064-0.18732-0.19685 0-0.35878 0.11112-0.15875 0.11112-0.26988 0.30162-0.11112 0.18732-0.17145 0.43815-0.06032 0.25082-0.06032 0.53022 0 0.39687 0.13335 0.5842 0.13335 0.18415 0.40958 0.18415zm-0.07303 0.60325q-0.26988 0-0.49848-0.0889-0.2286-0.09208-0.39688-0.2667-0.16828-0.17462-0.26352-0.42862t-0.09525-0.58102q0-0.42228 0.13652-0.7874t0.3683-0.63182q0.23495-0.2667 0.5461-0.4191t0.66358-0.1524q0.26988 0 0.49848 0.09208 0.23178 0.0889 0.39688 0.26352 0.16828 0.17462 0.26352 0.42862t0.09525 0.58102q0 0.4191-0.13652 0.78422-0.13652 0.36195-0.37148 0.63182-0.23178 0.2667-0.54292 0.42228-0.31115 0.1524-0.66358 0.1524z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m43.469 74.653q-0.16192 0.1778-0.31115 0.29845-0.14605 0.12065-0.29845 0.19368-0.1524 0.07302-0.32068 0.1016-0.1651 0.03175-0.36512 0.03175-0.28258 0-0.508-0.09525-0.22225-0.09843-0.37782-0.27622-0.15558-0.1778-0.23812-0.42862t-0.08255-0.5588q0-0.26352 0.05715-0.51435t0.1651-0.47308 0.26035-0.4064 0.3429-0.3175 0.41275-0.20638q0.22542-0.0762 0.47625-0.0762 0.32385 0 0.54928 0.1143 0.2286 0.1143 0.40322 0.3429l-0.25082 0.29845q-0.0254 0.02858-0.0635 0.05398-0.0381 0.02222-0.08255 0.02222-0.05398 0-0.09842-0.03175-0.04127-0.03493-0.1016-0.07303-0.05715-0.0381-0.14605-0.06985-0.08573-0.03493-0.22542-0.03493-0.18098 0-0.34608 0.09842-0.1651 0.09843-0.2921 0.2794-0.127 0.18098-0.2032 0.43498-0.07303 0.254-0.07303 0.56198 0 0.35877 0.15875 0.55562 0.15875 0.19368 0.4318 0.19368 0.12065 0 0.21272-0.0254 0.09208-0.0254 0.16192-0.06032 0.07302-0.0381 0.127-0.07937 0.05398-0.04445 0.1016-0.07937 0.04762-0.0381 0.0889-0.0635 0.04445-0.0254 0.09525-0.0254 0.04127 0 0.0762 0.02223 0.0381 0.01905 0.06668 0.0508l0.19685 0.2413z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m45.777 71.973-0.24448 2.0669q-0.04127 0.32067 0.03175 0.46037 0.07303 0.13652 0.2667 0.13652 0.127 0 0.25718-0.06985 0.13335-0.06985 0.25718-0.19685 0.12382-0.13018 0.2286-0.31432 0.10478-0.18415 0.17462-0.40958l0.20002-1.6732h0.78422l-0.3937 3.2575h-0.39688q-0.2794 0-0.2794-0.26988l0.01905-0.37465q-0.24765 0.34925-0.54292 0.5207-0.2921 0.17145-0.60642 0.17145-0.2032 0-0.36512-0.08255t-0.2667-0.23812q-0.10478-0.15875-0.14922-0.39052-0.04127-0.23178-0.0032-0.52705l0.24448-2.0669z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m48.86 75.231 0.3937-3.2607h0.40005q0.12382 0 0.1905 0.06032 0.06985 0.06033 0.06985 0.19368l-0.02222 0.53022q0.12382-0.2413 0.26352-0.41275 0.14288-0.17462 0.29528-0.26988 0.1524-0.09842 0.31115-0.1143 0.16192-0.01905 0.32702 0.0508l-0.14288 0.7493q-0.39052-0.16192-0.68262 0.08255-0.28892 0.2413-0.46355 0.9525l-0.17145 1.4383z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m54.588 72.729q0 0.21908-0.0889 0.40005-0.08572 0.18098-0.31115 0.32385-0.22542 0.14288-0.61278 0.24765-0.38418 0.1016-0.98108 0.16192v0.04127q0 0.76835 0.65088 0.76835 0.1397 0 0.24765-0.0254 0.10795-0.02857 0.18732-0.06667 0.08255-0.0381 0.14605-0.08573 0.06667-0.04762 0.12065-0.08572 0.05715-0.0381 0.11112-0.0635 0.05398-0.02858 0.11748-0.02858 0.0381 0 0.07303 0.01905 0.0381 0.01905 0.06667 0.0508l0.20002 0.2413q-0.16828 0.16828-0.3302 0.2921t-0.3302 0.2032q-0.16828 0.07937-0.3556 0.11748-0.18732 0.0381-0.4064 0.0381-0.28575 0-0.51752-0.09525-0.23178-0.09843-0.39688-0.27305-0.1651-0.1778-0.25718-0.42545-0.0889-0.25082-0.0889-0.5588 0-0.25718 0.05398-0.50482 0.05715-0.24765 0.16192-0.4699t0.25718-0.40958 0.3429-0.32385q0.19368-0.13652 0.42228-0.21272 0.23178-0.0762 0.4953-0.0762 0.254 0 0.4445 0.07303 0.19368 0.07303 0.32068 0.1905 0.13018 0.1143 0.19368 0.25718t0.0635 0.2794zm-1.0668-0.254q-0.15875 0-0.29528 0.06668-0.13652 0.0635-0.24765 0.18098-0.10795 0.11748-0.1905 0.28258-0.08255 0.16192-0.13018 0.3556 0.3937-0.05397 0.63182-0.11748 0.2413-0.0635 0.37148-0.1397 0.13335-0.0762 0.17462-0.16192 0.04445-0.08572 0.04445-0.18415 0-0.04762-0.02223-0.09842-0.01905-0.0508-0.0635-0.0889-0.04127-0.04127-0.11112-0.06667-0.06667-0.02858-0.16192-0.02858z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m55.709 75.231 0.3937-3.2607h0.40005q0.12382 0 0.1905 0.06032 0.06985 0.06033 0.06985 0.19368l-0.01588 0.35878q0.21908-0.33338 0.47625-0.4953 0.25718-0.1651 0.53658-0.1651 0.29845 0 0.47308 0.20002t0.2032 0.57785q0.2286-0.40005 0.50482-0.58738 0.2794-0.1905 0.60642-0.1905 0.20638 0 0.36195 0.08255t0.254 0.2413q0.09843 0.15558 0.13335 0.38735 0.03493 0.2286-0.0032 0.52388l-0.24448 2.0733h-0.7747l0.24448-2.0733q0.04127-0.3175-0.01905-0.45402-0.05715-0.1397-0.23495-0.1397-0.12065 0-0.23495 0.0635t-0.21908 0.18415q-0.1016 0.12065-0.1905 0.29528-0.08573 0.17462-0.1524 0.3937l-0.2032 1.7304h-0.7747l0.24765-2.0733q0.0381-0.3175-0.01588-0.45402-0.05397-0.1397-0.23495-0.1397-0.127 0-0.24448 0.06668-0.1143 0.06668-0.21908 0.19368-0.1016 0.12382-0.1905 0.3048-0.08573 0.1778-0.1524 0.40005l-0.2032 1.7018z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m64.303 72.729q0 0.21908-0.0889 0.40005-0.08572 0.18098-0.31115 0.32385-0.22542 0.14288-0.61278 0.24765-0.38418 0.1016-0.98108 0.16192v0.04127q0 0.76835 0.65088 0.76835 0.1397 0 0.24765-0.0254 0.10795-0.02857 0.18732-0.06667 0.08255-0.0381 0.14605-0.08573 0.06668-0.04762 0.12065-0.08572 0.05715-0.0381 0.11112-0.0635 0.05398-0.02858 0.11748-0.02858 0.0381 0 0.07303 0.01905 0.0381 0.01905 0.06667 0.0508l0.20002 0.2413q-0.16828 0.16828-0.3302 0.2921t-0.3302 0.2032q-0.16828 0.07937-0.3556 0.11748-0.18732 0.0381-0.4064 0.0381-0.28575 0-0.51752-0.09525-0.23178-0.09843-0.39688-0.27305-0.1651-0.1778-0.25718-0.42545-0.0889-0.25082-0.0889-0.5588 0-0.25718 0.05397-0.50482 0.05715-0.24765 0.16192-0.4699t0.25718-0.40958 0.3429-0.32385q0.19368-0.13652 0.42228-0.21272 0.23178-0.0762 0.4953-0.0762 0.254 0 0.4445 0.07303 0.19368 0.07303 0.32068 0.1905 0.13018 0.1143 0.19368 0.25718t0.0635 0.2794zm-1.0668-0.254q-0.15875 0-0.29528 0.06668-0.13652 0.0635-0.24765 0.18098-0.10795 0.11748-0.1905 0.28258-0.08255 0.16192-0.13018 0.3556 0.3937-0.05397 0.63182-0.11748 0.2413-0.0635 0.37148-0.1397 0.13335-0.0762 0.17462-0.16192 0.04445-0.08572 0.04445-0.18415 0-0.04762-0.02222-0.09842-0.01905-0.0508-0.0635-0.0889-0.04127-0.04127-0.11112-0.06667-0.06668-0.02858-0.16192-0.02858z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m65.427 75.231 0.39052-3.2607h0.39688q0.12382 0 0.1905 0.06032 0.06985 0.06033 0.06985 0.19368l-0.01905 0.42228q0.24765-0.36512 0.54928-0.54292 0.30162-0.18098 0.6223-0.18098 0.2032 0 0.36512 0.08255t0.2667 0.2413q0.10478 0.15558 0.14605 0.38735 0.04445 0.2286 0.0064 0.52388l-0.24448 2.0733h-0.78422l0.24448-2.0733q0.04127-0.3175-0.03175-0.45402-0.07303-0.1397-0.2667-0.1397-0.13018 0-0.26988 0.0762-0.13652 0.07303-0.26352 0.21272-0.127 0.13652-0.23495 0.3302-0.10478 0.1905-0.17145 0.42862l-0.1778 1.6192z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m69.885 74.567q0-0.02222 0-0.0508 0-0.03175 0.0032-0.0889 0.0063-0.06033 0.01588-0.15558 0.0127-0.09842 0.03175-0.25082l0.18098-1.4605h-0.30798q-0.05715 0-0.09525-0.04127-0.03493-0.04127-0.02223-0.12382l0.03493-0.31115 0.4953-0.08573 0.26035-0.889q0.02222-0.0635 0.06668-0.09843 0.04445-0.03493 0.11112-0.03493h0.4191l-0.127 1.0255h0.7874l-0.06668 0.5588h-0.79058l-0.17462 1.4192q-0.01588 0.13018-0.0254 0.20638-0.0095 0.0762-0.01587 0.12065-0.0032 0.04127-0.0032 0.06032 0 0.01588 0 0.02223 0 0.12065 0.05715 0.1905 0.06033 0.06985 0.16828 0.06985 0.06668 0 0.1143-0.01588 0.04763-0.01905 0.08255-0.0381 0.03493-0.01905 0.0635-0.03493 0.02858-0.01905 0.05398-0.01905 0.03493 0 0.05397 0.01905 0.01905 0.01588 0.03493 0.0508l0.16828 0.381q-0.17462 0.14288-0.38735 0.2159-0.20955 0.07303-0.42862 0.07303-0.34608 0-0.54928-0.18732-0.20002-0.18732-0.20955-0.52705z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m77.714 73.396q0.02858 0.08255 0.0508 0.16828 0.02222 0.08573 0.04445 0.17462 0.0381-0.0889 0.08255-0.17462 0.04445-0.08572 0.09525-0.16828l1.5938-2.6226q0.0381-0.04127 0.06668-0.06668 0.03175-0.02857 0.0635-0.04127 0.03175-0.01587 0.06985-0.01905 0.0381-0.0063 0.09207-0.0063h0.62548l-0.56515 4.591h-0.7239l0.3683-2.9908q0.0095-0.07937 0.0254-0.17462 0.01905-0.09843 0.04445-0.20002l-1.578 2.6162q-0.05398 0.0889-0.13652 0.13335-0.07937 0.04445-0.17462 0.04445h-0.1143q-0.09525 0-0.16828-0.04445-0.06985-0.04445-0.1016-0.13335l-0.94615-2.613q-0.0032 0.09842-0.0095 0.1905-0.0064 0.09207-0.0127 0.16828l-0.36195 3.0035h-0.72708l0.56515-4.591h0.61912q0.05397 0 0.0889 0.0063 0.0381 0.0032 0.06668 0.01905 0.02857 0.0127 0.05397 0.04127 0.0254 0.0254 0.05398 0.06668l0.94932 2.6226z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m84.067 75.231h-0.41592q-0.14922 0-0.2032-0.07303-0.0508-0.0762-0.0508-0.18732l0.0254-0.4191q-0.2159 0.33655-0.48578 0.5334-0.26988 0.19368-0.59372 0.19368-0.18415 0-0.34608-0.07303-0.15875-0.07303-0.2794-0.2159-0.11748-0.14605-0.18732-0.36195-0.06668-0.21908-0.06668-0.51117 0-0.2921 0.07303-0.56832t0.20638-0.51752q0.13652-0.24448 0.32702-0.44768t0.42545-0.34925q0.23812-0.14605 0.51118-0.22542 0.27622-0.08255 0.58102-0.08255 0.21908 0 0.43815 0.03175 0.22225 0.03175 0.4318 0.1143zm-1.416-0.59372q0.13018 0 0.254-0.0889 0.127-0.09208 0.23812-0.24765 0.11112-0.15875 0.20638-0.37147t0.1651-0.4572l0.12382-0.98108q-0.08572-0.0095-0.16192-0.0095-0.26352 0-0.48895 0.12065t-0.39052 0.32702q-0.16192 0.2032-0.25718 0.47625-0.09208 0.26988-0.09208 0.5715 0 0.3429 0.10795 0.50165t0.29528 0.15875z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m85.585 75.231 0.39052-3.2607h0.39688q0.12382 0 0.1905 0.06032 0.06985 0.06033 0.06985 0.19368l-0.01905 0.42228q0.24765-0.36512 0.54928-0.54292 0.30162-0.18098 0.6223-0.18098 0.2032 0 0.36512 0.08255t0.2667 0.2413q0.10478 0.15558 0.14605 0.38735 0.04445 0.2286 0.0063 0.52388l-0.24448 2.0733h-0.78422l0.24448-2.0733q0.04127-0.3175-0.03175-0.45402-0.07303-0.1397-0.2667-0.1397-0.13018 0-0.26988 0.0762-0.13652 0.07303-0.26352 0.21272-0.127 0.13652-0.23495 0.3302-0.10478 0.1905-0.17145 0.42862l-0.1778 1.6192z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m92.398 75.231h-0.41592q-0.14922 0-0.2032-0.07303-0.0508-0.0762-0.0508-0.18732l0.0254-0.4191q-0.2159 0.33655-0.48578 0.5334-0.26988 0.19368-0.59372 0.19368-0.18415 0-0.34608-0.07303-0.15875-0.07303-0.2794-0.2159-0.11748-0.14605-0.18732-0.36195-0.06668-0.21908-0.06668-0.51117 0-0.2921 0.07303-0.56832t0.20638-0.51752q0.13652-0.24448 0.32702-0.44768t0.42545-0.34925q0.23812-0.14605 0.51118-0.22542 0.27622-0.08255 0.58102-0.08255 0.21908 0 0.43815 0.03175 0.22225 0.03175 0.4318 0.1143zm-1.416-0.59372q0.13018 0 0.254-0.0889 0.127-0.09208 0.23812-0.24765 0.11112-0.15875 0.20638-0.37147t0.1651-0.4572l0.12382-0.98108q-0.08572-0.0095-0.16192-0.0095-0.26352 0-0.48895 0.12065t-0.39052 0.32702q-0.16192 0.2032-0.25718 0.47625-0.09207 0.26988-0.09207 0.5715 0 0.3429 0.10795 0.50165t0.29528 0.15875z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m96.539 72.916q0 0.14605-0.03175 0.26352-0.03175 0.1143-0.07937 0.21272-0.04445 0.09842-0.09842 0.18732-0.05398 0.08572-0.1016 0.1778-0.04445 0.0889-0.0762 0.1905-0.03175 0.09843-0.03175 0.21908 0 0.13017 0.05397 0.2413 0.05398 0.10795 0.11748 0.22225 0.0635 0.1143 0.11748 0.24448 0.05397 0.13018 0.05397 0.30162-0.0032 0.2413-0.11112 0.4572-0.10795 0.21908-0.31115 0.381-0.20002 0.1651-0.49212 0.26035t-0.6604 0.09525q-0.29845 0-0.53975-0.0635-0.2413-0.06033-0.40958-0.17145-0.16828-0.11112-0.26035-0.26352-0.09207-0.14922-0.09207-0.3302 0-0.22225 0.09525-0.38735t0.254-0.27622q0.15875-0.1143 0.36512-0.18098 0.20638-0.06668 0.42862-0.09208 0.22225-0.02857 0.4445-0.02222 0.22225 0.0063 0.41275 0.0381-0.02858-0.09843-0.02858-0.20955 0-0.18415 0.1016-0.4064-0.12382 0.08255-0.2794 0.127-0.1524 0.04445-0.36512 0.04445-0.18415 0-0.35242-0.06032-0.1651-0.06033-0.2921-0.18415-0.127-0.12382-0.2032-0.30798-0.07303-0.18732-0.07303-0.43498 0-0.23812 0.0889-0.46355 0.0889-0.2286 0.26352-0.4064 0.17462-0.18098 0.4318-0.28892t0.5969-0.10795q0.18098 0 0.3429 0.04445 0.1651 0.04128 0.30162 0.12382h0.91122l-0.03493 0.254q-0.02223 0.1524-0.15558 0.2032l-0.33338 0.0889q0.01588 0.0635 0.02223 0.13335 0.0095 0.06985 0.0095 0.14605zm-1.3018 0.7874q0.14288 0 0.25082-0.06668t0.18098-0.17462q0.0762-0.10795 0.1143-0.2413 0.0381-0.13652 0.04127-0.27622 0-0.23812-0.1143-0.36512-0.11112-0.127-0.32385-0.127-0.14288 0-0.25082 0.0635-0.10795 0.06032-0.18098 0.1651-0.07303 0.1016-0.11112 0.23495-0.03493 0.13335-0.03493 0.27622 0 0.24448 0.11112 0.37782t0.3175 0.13335zm0.51118 1.5399q0-0.04445-0.0063-0.08572-0.0032-0.04445-0.0095-0.08255-0.37465-0.0635-0.64135-0.05398-0.2667 0.0095-0.43498 0.06668-0.16828 0.05715-0.24765 0.1524-0.07937 0.09525-0.07937 0.2032 0 0.1905 0.1524 0.28892 0.15558 0.09843 0.47308 0.09843 0.1778 0 0.32385-0.04445t0.25082-0.12382q0.10478-0.07937 0.16192-0.18732 0.05715-0.10795 0.05715-0.23178z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m100.59 72.729q0 0.21908-0.0889 0.40005-0.0857 0.18098-0.31115 0.32385-0.22542 0.14288-0.61277 0.24765-0.38418 0.1016-0.98108 0.16192v0.04127q0 0.76835 0.65088 0.76835 0.1397 0 0.24765-0.0254 0.10795-0.02857 0.18732-0.06667 0.08255-0.0381 0.14605-0.08573 0.06668-0.04762 0.12065-0.08572 0.05715-0.0381 0.11113-0.0635 0.054-0.02858 0.11747-0.02858 0.0381 0 0.073 0.01905 0.0381 0.01905 0.0667 0.0508l0.20003 0.2413q-0.16828 0.16828-0.3302 0.2921-0.16193 0.12382-0.3302 0.2032-0.16828 0.07937-0.3556 0.11748-0.18732 0.0381-0.4064 0.0381-0.28575 0-0.51752-0.09525-0.23178-0.09843-0.39688-0.27305-0.1651-0.1778-0.25718-0.42545-0.0889-0.25082-0.0889-0.5588 0-0.25718 0.05397-0.50482 0.05715-0.24765 0.16192-0.4699t0.25718-0.40958 0.3429-0.32385q0.19368-0.13652 0.42228-0.21272 0.23178-0.0762 0.4953-0.0762 0.254 0 0.44449 0.07303 0.19368 0.07303 0.32068 0.1905 0.13017 0.1143 0.19367 0.25718t0.0635 0.2794zm-1.0668-0.254q-0.15875 0-0.29528 0.06668-0.13652 0.0635-0.24765 0.18098-0.10795 0.11748-0.1905 0.28258-0.08255 0.16192-0.13018 0.3556 0.3937-0.05397 0.63182-0.11748 0.2413-0.0635 0.37148-0.1397 0.13335-0.0762 0.17462-0.16192 0.04445-0.08572 0.04445-0.18415 0-0.04762-0.02223-0.09842-0.01905-0.0508-0.0635-0.0889-0.04127-0.04127-0.11112-0.06667-0.06667-0.02858-0.16192-0.02858z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m101.71 75.231 0.3937-3.2607h0.40005q0.12382 0 0.1905 0.06032 0.0698 0.06033 0.0698 0.19368l-0.0159 0.35878q0.21908-0.33338 0.47625-0.4953 0.25718-0.1651 0.53658-0.1651 0.29845 0 0.47307 0.20002 0.17463 0.20002 0.2032 0.57785 0.2286-0.40005 0.50483-0.58738 0.2794-0.1905 0.60642-0.1905 0.20638 0 0.36195 0.08255 0.15558 0.08255 0.254 0.2413 0.0984 0.15558 0.13335 0.38735 0.0349 0.2286-3e-3 0.52388l-0.24448 2.0733h-0.7747l0.24448-2.0733q0.0413-0.3175-0.0191-0.45402-0.0571-0.1397-0.23495-0.1397-0.12065 0-0.23495 0.0635t-0.21908 0.18415q-0.1016 0.12065-0.1905 0.29528-0.0857 0.17462-0.1524 0.3937l-0.2032 1.7304h-0.7747l0.24765-2.0733q0.0381-0.3175-0.0159-0.45402-0.054-0.1397-0.23495-0.1397-0.127 0-0.24448 0.06668-0.1143 0.06668-0.21907 0.19368-0.1016 0.12382-0.1905 0.3048-0.0857 0.1778-0.1524 0.40005l-0.2032 1.7018z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m110.31 72.729q0 0.21908-0.0889 0.40005-0.0857 0.18098-0.31115 0.32385-0.22543 0.14288-0.61278 0.24765-0.38417 0.1016-0.98107 0.16192v0.04127q0 0.76835 0.65087 0.76835 0.1397 0 0.24765-0.0254 0.10795-0.02857 0.18733-0.06667 0.0825-0.0381 0.14605-0.08573 0.0667-0.04762 0.12065-0.08572 0.0572-0.0381 0.11112-0.0635 0.054-0.02858 0.11748-0.02858 0.0381 0 0.073 0.01905 0.0381 0.01905 0.0667 0.0508l0.20002 0.2413q-0.16827 0.16828-0.3302 0.2921-0.16192 0.12382-0.3302 0.2032-0.16827 0.07937-0.3556 0.11748-0.18732 0.0381-0.4064 0.0381-0.28575 0-0.51752-0.09525-0.23178-0.09843-0.39688-0.27305-0.1651-0.1778-0.25717-0.42545-0.0889-0.25082-0.0889-0.5588 0-0.25718 0.054-0.50482 0.0572-0.24765 0.16193-0.4699 0.10477-0.22225 0.25717-0.40958t0.3429-0.32385q0.19368-0.13652 0.42228-0.21272 0.23177-0.0762 0.4953-0.0762 0.254 0 0.4445 0.07303 0.19367 0.07303 0.32067 0.1905 0.13018 0.1143 0.19368 0.25718t0.0635 0.2794zm-1.0668-0.254q-0.15875 0-0.29528 0.06668-0.13652 0.0635-0.24765 0.18098-0.10795 0.11748-0.1905 0.28258-0.0825 0.16192-0.13017 0.3556 0.3937-0.05397 0.63182-0.11748 0.2413-0.0635 0.37148-0.1397 0.13335-0.0762 0.17462-0.16192 0.0445-0.08572 0.0445-0.18415 0-0.04762-0.0222-0.09842-0.0191-0.0508-0.0635-0.0889-0.0413-0.04127-0.11113-0.06667-0.0667-0.02858-0.16192-0.02858z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m111.43 75.231 0.39052-3.2607h0.39688q0.12382 0 0.1905 0.06032 0.0699 0.06033 0.0699 0.19368l-0.0191 0.42228q0.24765-0.36512 0.54927-0.54292 0.30163-0.18098 0.6223-0.18098 0.2032 0 0.36513 0.08255 0.16192 0.08255 0.2667 0.2413 0.10477 0.15558 0.14605 0.38735 0.0444 0.2286 6e-3 0.52388l-0.24448 2.0733h-0.78422l0.24447-2.0733q0.0413-0.3175-0.0318-0.45402-0.073-0.1397-0.2667-0.1397-0.13017 0-0.26987 0.0762-0.13653 0.07303-0.26353 0.21272-0.127 0.13652-0.23495 0.3302-0.10477 0.1905-0.17145 0.42862l-0.1778 1.6192z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m115.89 74.567q0-0.02222 0-0.0508 0-0.03175 3e-3 -0.0889 6e-3 -0.06033 0.0159-0.15558 0.0127-0.09842 0.0318-0.25082l0.18097-1.4605h-0.30797q-0.0572 0-0.0953-0.04127-0.0349-0.04127-0.0222-0.12382l0.0349-0.31115 0.4953-0.08573 0.26035-0.889q0.0222-0.0635 0.0667-0.09843t0.11113-0.03493h0.4191l-0.127 1.0255h0.7874l-0.0667 0.5588h-0.79057l-0.17463 1.4192q-0.0159 0.13018-0.0254 0.20638-0.01 0.0762-0.0159 0.12065-3e-3 0.04127-3e-3 0.06032 0 0.01588 0 0.02223 0 0.12065 0.0572 0.1905 0.0603 0.06985 0.16828 0.06985 0.0667 0 0.1143-0.01588 0.0476-0.01905 0.0825-0.0381t0.0635-0.03493q0.0286-0.01905 0.054-0.01905 0.0349 0 0.054 0.01905 0.019 0.01588 0.0349 0.0508l0.16828 0.381q-0.17463 0.14288-0.38735 0.2159-0.20955 0.07303-0.42863 0.07303-0.34607 0-0.54927-0.18732-0.20003-0.18732-0.20955-0.52705z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m124.06 71.465q-0.0444 0.0635-0.0889 0.09525-0.0413 0.02858-0.10478 0.02858-0.0635 0-0.13017-0.0508-0.0667-0.0508-0.15875-0.11112-0.0921-0.0635-0.21908-0.1143-0.127-0.0508-0.3048-0.0508-0.17145 0-0.3048 0.0508t-0.22542 0.1397q-0.0889 0.0889-0.13653 0.20955-0.0444 0.12065-0.0444 0.26035 0 0.14605 0.073 0.24448 0.0762 0.09525 0.19685 0.16828 0.12065 0.07303 0.27622 0.13018 0.15558 0.05715 0.31433 0.12065 0.16192 0.0635 0.3175 0.14605 0.15557 0.08255 0.27622 0.2032t0.19368 0.2921q0.0762 0.16828 0.0762 0.40958 0 0.33655-0.11748 0.635-0.11747 0.29845-0.33337 0.52388-0.2159 0.22225-0.52705 0.3556-0.31115 0.13018-0.69533 0.13018-0.20002 0-0.3937-0.04445-0.19367-0.04127-0.36512-0.12065-0.17145-0.07937-0.3175-0.1905-0.14605-0.11112-0.254-0.24765l0.2921-0.3937q0.0349-0.04445 0.0889-0.07302t0.10795-0.02857q0.0508 0 0.0984 0.03175 0.0508 0.03175 0.10795 0.07937 0.0603 0.04445 0.127 0.1016 0.0699 0.05397 0.15558 0.1016 0.0889 0.04445 0.20002 0.0762 0.1143 0.03175 0.25718 0.03175 0.36195 0 0.56515-0.2032 0.2032-0.2032 0.2032-0.56197 0-0.15875-0.073-0.26035t-0.19367-0.17145q-0.12065-0.06985-0.27623-0.12065-0.1524-0.05397-0.31115-0.11112-0.15875-0.06032-0.31432-0.13652-0.1524-0.07938-0.27305-0.20002-0.12065-0.12382-0.19368-0.30162-0.073-0.1778-0.073-0.43815 0-0.29528 0.10795-0.56832 0.11112-0.27305 0.31432-0.47942 0.20638-0.20955 0.4953-0.33338 0.2921-0.127 0.65723-0.127 0.18732 0 0.35877 0.0381 0.17463 0.0381 0.32385 0.10795 0.14923 0.06985 0.27305 0.16828 0.127 0.09525 0.21908 0.21272z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m126.11 76.132q-0.0444 0.08255-0.10477 0.12065-0.0603 0.04127-0.14923 0.04127h-0.58102l0.7239-1.3113-0.82233-3.0099h0.65723q0.0857 0 0.127 0.04445 0.0444 0.04127 0.0603 0.1016l0.40005 1.6891q0.0318 0.16192 0.0476 0.32385 0.0349-0.08255 0.0699-0.16192 0.0381-0.08255 0.0762-0.1651l0.80327-1.6891q0.0318-0.0635 0.0921-0.1016 0.0603-0.04127 0.12382-0.04127h0.62865z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m131.14 72.586q-0.0349 0.0508-0.0667 0.07303-0.0317 0.01905-0.0825 0.01905-0.0572 0-0.1143-0.03175-0.0572-0.03175-0.13335-0.06985-0.073-0.04127-0.17145-0.07302-0.0953-0.03175-0.2286-0.03175-0.25082 0-0.38417 0.1143-0.13335 0.11112-0.13335 0.2794 0 0.13018 0.0921 0.20955 0.0952 0.07938 0.23813 0.1397 0.14287 0.06033 0.3048 0.12382 0.1651 0.06033 0.30797 0.15558 0.14288 0.09208 0.23495 0.2413 0.0953 0.14605 0.0953 0.37782 0 0.23495-0.0953 0.44767-0.0921 0.21272-0.2667 0.37465-0.17462 0.15875-0.42227 0.254-0.24448 0.09207-0.55245 0.09207-0.1524 0-0.29845-0.03175-0.14605-0.02857-0.27305-0.08255-0.12383-0.05397-0.2286-0.127-0.10478-0.07303-0.1778-0.15875l0.19367-0.29845q0.0349-0.05398 0.0825-0.08255 0.0476-0.02858 0.11748-0.02858 0.0667 0 0.12065 0.04127 0.054 0.04127 0.12382 0.09207 0.073 0.04763 0.17463 0.0889 0.10477 0.04127 0.2667 0.04127 0.13017 0 0.2286-0.03493 0.1016-0.03493 0.17145-0.09525 0.0699-0.06032 0.10477-0.1397 0.0349-0.07937 0.0349-0.16828 0-0.10795-0.054-0.1778-0.054-0.07303-0.14605-0.12382-0.0889-0.05398-0.2032-0.09208-0.1143-0.04127-0.23495-0.08572-0.11747-0.04445-0.23177-0.1016-0.11113-0.05715-0.2032-0.14288-0.0889-0.0889-0.14288-0.21272-0.054-0.127-0.054-0.30798 0-0.2159 0.0825-0.4191 0.0825-0.20638 0.2413-0.36195 0.15875-0.15875 0.39052-0.254 0.23495-0.09525 0.53658-0.09525 0.31115 0 0.55245 0.10795 0.2413 0.10478 0.40322 0.26988z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m132.61 74.567q0-0.02222 0-0.0508 0-0.03175 3e-3 -0.0889 6e-3 -0.06033 0.0159-0.15558 0.0127-0.09842 0.0318-0.25082l0.18098-1.4605h-0.30798q-0.0572 0-0.0952-0.04127-0.0349-0.04127-0.0222-0.12382l0.0349-0.31115 0.4953-0.08573 0.26035-0.889q0.0222-0.0635 0.0667-0.09843t0.11112-0.03493h0.4191l-0.127 1.0255h0.7874l-0.0667 0.5588h-0.79058l-0.17462 1.4192q-0.0159 0.13018-0.0254 0.20638-0.01 0.0762-0.0159 0.12065-3e-3 0.04127-3e-3 0.06032 0 0.01588 0 0.02223 0 0.12065 0.0571 0.1905 0.0603 0.06985 0.16827 0.06985 0.0667 0 0.1143-0.01588 0.0476-0.01905 0.0825-0.0381t0.0635-0.03493q0.0286-0.01905 0.054-0.01905 0.0349 0 0.054 0.01905 0.019 0.01588 0.0349 0.0508l0.16827 0.381q-0.17462 0.14288-0.38735 0.2159-0.20955 0.07303-0.42862 0.07303-0.34608 0-0.54928-0.18732-0.20002-0.18732-0.20955-0.52705z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m138.15 72.729q0 0.21908-0.0889 0.40005-0.0857 0.18098-0.31115 0.32385-0.22542 0.14288-0.61277 0.24765-0.38418 0.1016-0.98108 0.16192v0.04127q0 0.76835 0.65088 0.76835 0.1397 0 0.24765-0.0254 0.10795-0.02857 0.18732-0.06667 0.0825-0.0381 0.14605-0.08573 0.0667-0.04762 0.12065-0.08572 0.0572-0.0381 0.11113-0.0635 0.054-0.02858 0.11747-0.02858 0.0381 0 0.073 0.01905 0.0381 0.01905 0.0667 0.0508l0.20003 0.2413q-0.16828 0.16828-0.3302 0.2921-0.16193 0.12382-0.3302 0.2032-0.16828 0.07937-0.3556 0.11748-0.18733 0.0381-0.4064 0.0381-0.28575 0-0.51753-0.09525-0.23177-0.09843-0.39687-0.27305-0.1651-0.1778-0.25718-0.42545-0.0889-0.25082-0.0889-0.5588 0-0.25718 0.054-0.50482 0.0572-0.24765 0.16192-0.4699 0.10478-0.22225 0.25718-0.40958t0.3429-0.32385q0.19367-0.13652 0.42227-0.21272 0.23178-0.0762 0.4953-0.0762 0.254 0 0.4445 0.07303 0.19368 0.07303 0.32068 0.1905 0.13017 0.1143 0.19367 0.25718t0.0635 0.2794zm-1.0668-0.254q-0.15875 0-0.29527 0.06668-0.13653 0.0635-0.24765 0.18098-0.10795 0.11748-0.1905 0.28258-0.0825 0.16192-0.13018 0.3556 0.3937-0.05397 0.63183-0.11748 0.2413-0.0635 0.37147-0.1397 0.13335-0.0762 0.17463-0.16192 0.0444-0.08572 0.0444-0.18415 0-0.04762-0.0222-0.09842-0.0191-0.0508-0.0635-0.0889-0.0413-0.04127-0.11112-0.06667-0.0667-0.02858-0.16193-0.02858z"
                fill="#442178"
                strokeWidth=".26458"
              />
              <path
                d="m139.27 75.231 0.3937-3.2607h0.40005q0.12383 0 0.1905 0.06032 0.0699 0.06033 0.0699 0.19368l-0.0159 0.35878q0.21907-0.33338 0.47625-0.4953 0.25717-0.1651 0.53657-0.1651 0.29845 0 0.47308 0.20002 0.17462 0.20002 0.2032 0.57785 0.2286-0.40005 0.50482-0.58738 0.2794-0.1905 0.60643-0.1905 0.20637 0 0.36195 0.08255 0.15557 0.08255 0.254 0.2413 0.0984 0.15558 0.13335 0.38735 0.0349 0.2286-3e-3 0.52388l-0.24447 2.0733h-0.7747l0.24447-2.0733q0.0413-0.3175-0.019-0.45402-0.0572-0.1397-0.23495-0.1397-0.12065 0-0.23495 0.0635t-0.21907 0.18415q-0.1016 0.12065-0.1905 0.29528-0.0857 0.17462-0.1524 0.3937l-0.2032 1.7304h-0.7747l0.24765-2.0733q0.0381-0.3175-0.0159-0.45402-0.054-0.1397-0.23495-0.1397-0.127 0-0.24447 0.06668-0.1143 0.06668-0.21908 0.19368-0.1016 0.12382-0.1905 0.3048-0.0857 0.1778-0.1524 0.40005l-0.2032 1.7018z"
                fill="#442178"
                strokeWidth=".26458"
              />
            </g>
          </g>
        </g>
        <use
          transform="translate(-111.79 -13.406)"
          width="100%"
          height="100%"
        />
      </g>
    </svg>
  );
}; */

export { Logo };
