import {
  TextInput,
  required,
  useTranslate,
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  email,
  minLength,
  maxLength,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { isUrl, MediaInputField } from "../../../../lib/components";
import { Divider, Grid, Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { MAX_RECORDS_PER_PAGE } from "../../../../lib/common";
import {
  CategoryTypes,
  RESOURCE_NAME_CATEGORY,
} from "../../category/constants";
import { RESOURCE_NAME_VENDOR_GROUP } from "../../vendor-group";

const useStyles = makeStyles((theme) =>
  createStyles({
    vendorForm: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },

    phone: {
      display: "inline-block",

      marginLeft: 32,
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
      },
    },
    url: {
      display: "inline-block",
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
      },
    },
  })
);

const VendorForm = ({ isEdit = true, ...rest }) => {
  const validateName = [required(), minLength(2), maxLength(15)];
  const validateEmail = [required(), email()];
  const classes = useStyles();
  return (
    <SimpleForm warnWhenUnsavedChanges {...rest}>
      <Box pt={2}>
        <SectionTitle label="resources.vendors.fieldGroups.vendorDetails" />
      </Box>
      <Grid container lg={12} md={6} spacing={2} className={classes.vendorForm}>
        <Grid item>
          <TextInput
            autoFocus
            source="vendorAccountCode"
            label="resources.vendors.fields.vendorAccountCode"
            helperText="resources.vendors.help.vendorAccountCode"
            validate={[required()]}
            isRequired
          />
        </Grid>

        <Grid item>
          <TextInput
            source="vendorAccountName"
            label="resources.vendors.fields.vendorAccountId"
            helperText="resources.vendors.help.vendorAccountName"
            isRequired
            validate={[required()]}
          />
        </Grid>

        <Grid item>
          <TextInput
            multiline
            source="vendorAccountDescription"
            label="resources.vendors.fields.vendorAccountDescription"
            helperText="resources.vendors.help.vendorAccountDescription"
          />
        </Grid>

        <Grid item>
          <ReferenceInput
            label="resources.vendors.filters.vendorCategoryId"
            source="vendorCategoryId"
            reference={RESOURCE_NAME_CATEGORY}
            perPage={MAX_RECORDS_PER_PAGE}
            sort={{ field: "name", order: "ASC" }}
            filter={{ categoryType: CategoryTypes.VENDOR }}
            key="vendorCategoryId"
          >
            <AutocompleteInput
              optionText="displayLabel"
              resettable={true}
              allowEmpty={false}
            />
          </ReferenceInput>
        </Grid>

        <Grid item>
          <ReferenceInput
            label="resources.vendors.fields.vendorGroupId"
            source="vendorGroupId"
            reference={RESOURCE_NAME_VENDOR_GROUP}
            perPage={MAX_RECORDS_PER_PAGE}
            sort={{ field: "name", order: "ASC" }}
            key="vendorGroupId"
          >
            <AutocompleteInput
              optionText="displayLabel"
              resettable={true}
              allowEmpty={false}
            />
          </ReferenceInput>
        </Grid>
      </Grid>

      <Box pt={2} width="100% !important">
        <SectionTitle label="resources.vendors.fieldGroups.primaryUserDetails" />
      </Box>
      <Grid container lg={12} md={6} spacing={3} className={classes.vendorForm}>
        <Grid item>
          <TextInput
            source="primaryUserFirstName"
            label="resources.vendors.fields.primaryUserFirstName"
            helperText="resources.vendors.help.primaryUserFirstName"
            validate={validateName}
          />
        </Grid>
        <Grid item>
          <TextInput
            source="primaryUserLastName"
            label="resources.vendors.fields.primaryUserLastName"
            helperText="resources.vendors.help.primaryUserLastName"
            validate={validateName}
          />
        </Grid>
        <Grid item>
          <TextInput
            source="primaryUserEmail"
            label="resources.vendors.fields.primaryUserEmail"
            validate={validateEmail}
            helperText="resources.vendors.help.primaryUserEmail"
          />
        </Grid>
      </Grid>

      <Separator />
      <Box pt={2}>
        <SectionTitle label="resources.vendors.fieldGroups.addressDetails" />
      </Box>
      <Grid container spacing={4} className={classes.vendorForm}>
        <Grid item>
          <TextInput
            source="addressLine1"
            label="resources.vendors.fields.addressLine1"
            helperText="resources.vendors.help.addressLine1"
          />
        </Grid>

        <Grid item>
          <TextInput
            source="addressLine2"
            label="resources.vendors.fields.addressLine2"
            helperText="resources.vendors.help.addressLine2"
          />
        </Grid>

        <Grid item>
          <TextInput
            source="pincode"
            label="resources.vendors.fields.pincode"
            helperText="resources.vendors.help.pincode"
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} className={classes.vendorForm}>
        <Grid item>
          <TextInput
            source="city"
            label="resources.vendors.fields.city"
            helperText="resources.vendors.help.city"
          />
        </Grid>
        <Grid item>
          <TextInput
            source="state"
            label="resources.vendors.fields.state"
            helperText="resources.vendors.help.state"
          />
        </Grid>
        <Grid item>
          <TextInput
            source="country"
            label="resources.vendors.fields.country"
            helperText="resources.vendors.help.country"
          />
        </Grid>
      </Grid>
      <Divider />
      <Separator />
      <Box pt={2}>
        <SectionTitle label="resources.vendors.fieldGroups.contactInformation" />
      </Box>
      <ArrayInput source="phones">
        <SimpleFormIterator>
          <TextInput
            source="phone"
            helperText="resources.vendors.help.phones"
            resettable={true}
            validate={[required()]}
            isRequired
          />
        </SimpleFormIterator>
      </ArrayInput>

      <TextInput
        source="url"
        label="resources.vendors.fields.url"
        helperText="resources.vendors.help.url"
        formClassName={classes.url}
        type="url"
        validate={[isUrl()]}
        resettable={true}
      />

      <MediaInputField showUploadedFiles={false} />
    </SimpleForm>
  );
};
const Separator = () => <Box pt="1em" />;

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

export { VendorForm };
