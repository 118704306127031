import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setSidebarVisibility,
  ShowProps,
  Tab,
  TabbedShowLayout,
  useRecordContext,
} from "react-admin";
import { FILTER_DIRECTION, PermissionBehavior } from "../../common";
import { DefaultShow } from "../../lib/components";
import { usePermissionsCheck } from "../../lib/core";
import {
  BidEnquiryShowContainer,
  BidShowBidEnquiry,
  BidShowContent,
} from "./components";
import { BidCrudShowActions } from "./components/actions";
import { requisitionResource } from "../requisitions";
import { enquiryResource } from "../enquiries";
import { bidResource } from ".";
import { BidRequisitionShowContainer } from "./components/BidRequisitionShowContainer";
import { bidsComparisonResource } from "../bidsComparison";
import { EnquiryShowBidEnquiry } from "../enquiries/components";

const BidShow = (props: ShowProps) => {
  const dispatch = useDispatch();
  const { hasPermissionAccess } = usePermissionsCheck();
  const canProcure = hasPermissionAccess(PermissionBehavior.CAN_PROCURE);
  const direction = canProcure
    ? FILTER_DIRECTION.RECEIVED
    : FILTER_DIRECTION.SENT;

  const record = useRecordContext(props);

  const canViewBids = hasPermissionAccess(
    bidResource?.options?.allowedPermissions?.show
  );

  const canViewEnquiry = hasPermissionAccess(
    enquiryResource?.options?.allowedPermissions?.show
  );

  const canViewRequisitions = hasPermissionAccess(
    requisitionResource?.options?.allowedPermissions?.show
  );

  const canViewBidComparison = hasPermissionAccess(
    bidsComparisonResource?.options?.allowedPermissions?.show
  );

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
    // eslint-disable-next-line
  }, []);

  return (
    <DefaultShow
      {...props}
      actions={<BidCrudShowActions />}
      basePath={`/bids?filter={"direction":"${direction}"}`}
    >
      <TabbedShowLayout>
        {canViewBids && (
          <Tab label="resources.bids.fieldGroups.bidTab">
            <BidShowContent />
          </Tab>
        )}

        {canViewEnquiry && (
          <Tab label="resources.bids.fieldGroups.enquiryTab">
            <BidEnquiryShowContainer {...props} />
          </Tab>
        )}

        {/* {canViewRequisitions && (
          <Tab label="resources.bids.fieldGroups.requisitionTab">
            <BidRequisitionShowContainer {...props} />
          </Tab>
        )} */}

        {canViewBidComparison && (
          <Tab label="resources.enquiries.fieldGroups.bidsComparison">
            <BidShowBidEnquiry {...props} />
          </Tab>
        )}
      </TabbedShowLayout>
    </DefaultShow>
  );
};

export { BidShow };
