import { useState, useMemo } from "react";
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import {
  BulkActionProps,
  Button,
  useListContext,
  useUnselectAll,
  useNotify,
  Identifier,
  ConfirmProps,
} from "react-admin";
import AddToEnquiryIcon from "@material-ui/icons/AddShoppingCartTwoTone";
import RemoveFromEnquiryIcon from "@material-ui/icons/RemoveShoppingCartTwoTone";
import ExcludeFromEnquiryIcon from "@material-ui/icons/CallSplit";
import IncludeExcludesItemIcon from "@material-ui/icons/CallMerge";
import { DatagridColors } from "../constants";
import { RESOURCE_NAME_REQUISITION_DETAILS } from "../../requisitions/constants";
import { ConfirmButton, EMPTY_CONFIRM_HANDLER } from "../../../lib/components";
import { httpClient, Options } from "../../../lib/core";
import { EnquiryBulkActionButtonsForm } from "./EnquiryBulkActionButtonsForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addItemsToEnquiry: {
      color: DatagridColors.SELECTED_COLOR,
      backgroundColor: DatagridColors.SELECTED_BG_COLOR,
      padding: 8,
    },
    removeItemsFromEnquiry: {
      color: DatagridColors.UNSELECTED_COLOR,
      backgroundColor: DatagridColors.UNSELECTED_BG_COLOR,
      padding: 8,
    },
    excludeItemsFromEnquiry: {
      color: DatagridColors.EXCLUDED_COLOR,
      backgroundColor: DatagridColors.EXCLUDED_BG_COLOR,
      padding: 8,
    },
    includeExcludedItems: {
      color: DatagridColors.INCLUDE_EXCLUDED_COLOR,
      backgroundColor: DatagridColors.INCLUDE_EXCLUDED_BG_COLOR,
      padding: 8,
    },
    container: {
      display: "flex",
      gap: 10,
    },
  })
);

interface EnquiryBulkActionProps extends BulkActionProps {
  handleAddItemsToEnquiry: (map) => void;
  handleRemoveItemsFromEnquiry: (ids: Array<Identifier>) => void;
  enquiryDetailsMap: Map<any, any>;
}

const AddItemsToEnquiryBulkActionButton = (props: EnquiryBulkActionProps) => {
  const classes = useStyles();
  const { data, ids, selectedIds } = useListContext();
  const unselectAll = useUnselectAll();
  const notify = useNotify();
  const { handleAddItemsToEnquiry, enquiryDetailsMap, ...rest } = props;
  const addItemsToEnquiry = () => {
    const itemsToAddMap = new Map();
    selectedIds?.forEach((selectedId) => {
      const record = data[selectedId];
      const enquiryId = record?.enquiryId;
      const isNew = enquiryId ? false : true;
      const enquiryDetail = {
        requisitionId: record.requisitionId,
        requisitionDetailId: record.id,
        itemId: record.itemId,
        enquiryId,
        quantity: record.quantity,
        uomId: record.uomId,
        quantity2: record.quantity2,
        uom2Id: record.uom2Id,
        sortOrder: record?.sortOrder,
        //version: record.version,
        isNew,
      };
      itemsToAddMap.set(selectedId, enquiryDetail);
    });

    if (itemsToAddMap?.size) {
      handleAddItemsToEnquiry(itemsToAddMap);
    }

    unselectAll(RESOURCE_NAME_REQUISITION_DETAILS);
    notify("resources.enquiries.messages.addItemsToEnquiry", {
      type: "info",
      multiLine: true,
    });
  };
  return (
    <Button
      label="resources.enquiries.actions.addItemsToEnquiry"
      className={classes.addItemsToEnquiry}
      onClick={addItemsToEnquiry}
    >
      <AddToEnquiryIcon />
    </Button>
  );
};

const RemoveItemsFromEnquiryBulkActionButton = (
  props: EnquiryBulkActionProps
) => {
  const classes = useStyles();
  const { data, ids, selectedIds } = useListContext();
  const unselectAll = useUnselectAll();
  const notify = useNotify();
  const { handleRemoveItemsFromEnquiry, enquiryDetailsMap, ...rest } = props;

  const removeItemsFromEnquiry = () => {
    handleRemoveItemsFromEnquiry(selectedIds);
    unselectAll(RESOURCE_NAME_REQUISITION_DETAILS);
    notify("resources.enquiries.messages.removeItemsFromEnquiry", {
      type: "info",
      multiLine: true,
    });
  };

  return (
    <Button
      label="resources.enquiries.actions.removeItemsFromEnquiry"
      className={classes.removeItemsFromEnquiry}
      onClick={removeItemsFromEnquiry}
    >
      <RemoveFromEnquiryIcon />
    </Button>
  );
};

const ExcludeItemsFromEnquiryBulkActionButton = (
  props: EnquiryBulkActionProps
) => {
  const classes = useStyles();
  const [exclusionReason, setExclusionReason] = useState("");
  const { data, ids, selectedIds, refetch } = useListContext();
  const unselectAll = useUnselectAll();
  const notify = useNotify();
  const { handleAddItemsToEnquiry, enquiryDetailsMap, ...rest } = props;
  const apiUrl = `${process.env.REACT_APP_SERVER_ENDPOINT}/requisition/detail/actions/excludeItemsFromEnquiry`;

  const handleSubmitAction = async () => {
    const excludeRecords: Array<{
      requisitionId: string;
      requisitionDetailId: string;
    }> = [];

    selectedIds?.forEach((selectedId) => {
      const record = data[selectedId];
      const requisitionDetailId: string = record?.id?.toString();
      const requisitionId: string = record?.requisitionId;
      excludeRecords.push({
        requisitionId,
        requisitionDetailId,
      });
    });

    const body = JSON.stringify({
      exclusionReason,
      excludeRecords,
    });

    const options: Options = {
      method: "POST",
      body,
    };

    try {
      const apiResponse = await httpClient(apiUrl, options);
      unselectAll(RESOURCE_NAME_REQUISITION_DETAILS);
      refetch();
      notify(
        "resources.enquiries.actions.excludeItemsFromEnquiry.notification.success",
        {
          type: "success",
          multiLine: true,
        }
      );
    } catch (error) {
      unselectAll(RESOURCE_NAME_REQUISITION_DETAILS);
      notify(
        "resources.enquiries.actions.excludeItemsFromEnquiry.notification.failure",
        {
          type: "error",
          multiLine: true,
        }
      );
    }
  };

  const confirm: ConfirmProps = {
    title: "resources.enquiries.actions.excludeItemsFromEnquiry.title",
    onConfirm: handleSubmitAction,
    onClose: EMPTY_CONFIRM_HANDLER,
    ConfirmIcon: ExcludeFromEnquiryIcon,
    confirm: "resources.enquiries.actions.excludeItemsFromEnquiry.confirm",
    content: (
      <EnquiryBulkActionButtonsForm setExclusionReason={setExclusionReason} />
    ),
    confirmColor: "warning",
  };

  return (
    <ConfirmButton
      label="resources.enquiries.actions.excludeItemsFromEnquiry.label"
      className={classes.excludeItemsFromEnquiry}
      confirm={confirm}
      {...props}
    >
      <ExcludeFromEnquiryIcon />
    </ConfirmButton>
  );
};

const IncludeExcludedItemsBulkActionButton = (
  props: EnquiryBulkActionProps
) => {
  const classes = useStyles();
  const { data, ids, selectedIds, refetch } = useListContext();
  const unselectAll = useUnselectAll();
  const notify = useNotify();
  const { handleAddItemsToEnquiry, enquiryDetailsMap, ...rest } = props;
  const apiUrl = `${process.env.REACT_APP_SERVER_ENDPOINT}/requisition/detail/actions/includeExcludedItems`;

  const handleSubmitAction = async () => {
    const idsToInclude: Array<string> = [];

    selectedIds?.forEach((selectedId) => {
      const record = data[selectedId];
      const requisitionDetailId: string = record?.id?.toString();
      idsToInclude.push(requisitionDetailId);
    });

    const body = JSON.stringify({
      idsToInclude,
    });

    const options: Options = {
      method: "POST",
      body,
    };

    try {
      const apiResponse = await httpClient(apiUrl, options);
      unselectAll(RESOURCE_NAME_REQUISITION_DETAILS);
      refetch();
      notify(
        "resources.enquiries.actions.includeExcludedItems.notification.success",
        {
          type: "success",
          multiLine: true,
        }
      );
    } catch (error) {
      unselectAll(RESOURCE_NAME_REQUISITION_DETAILS);
      notify(
        "resources.enquiries.actions.includeExcludedItems.notification.failure",
        {
          type: "error",
          multiLine: true,
        }
      );
    }
  };

  const confirm: ConfirmProps = {
    title: "resources.enquiries.actions.includeExcludedItems.title",
    content: "resources.enquiries.actions.includeExcludedItems.message",
    onConfirm: handleSubmitAction,
    onClose: EMPTY_CONFIRM_HANDLER,
    ConfirmIcon: IncludeExcludesItemIcon,
    confirm: "resources.enquiries.actions.includeExcludedItems.confirm",
    confirmColor: "warning",
  };

  return (
    <ConfirmButton
      label="resources.enquiries.actions.includeExcludedItems.label"
      className={classes.includeExcludedItems}
      confirm={confirm}
      {...props}
    >
      <IncludeExcludesItemIcon />
    </ConfirmButton>
  );
};

const EnquiryBulkActionButtons = (props: EnquiryBulkActionProps) => {
  const classes = useStyles();
  const { data, ids, selectedIds } = useListContext();

  const [
    isAnySelectedRecordAddedToEnquiry,
    isAnySelectedRecordExcluded,
    areAllSelectedRecordsExcluded,
  ] = useMemo(() => {
    let isAnySelectedRecordAddedToEnquiry = false;
    let isAnySelectedRecordExcluded = false;
    let areAllSelectedRecordsExcluded = true;

    selectedIds?.forEach((selectedId) => {
      const record = data[selectedId];
      const isExcluded = record?.isExcluded;
      const isItemAddedToEnquiry = props?.enquiryDetailsMap?.has(selectedId);

      if (isExcluded) {
        isAnySelectedRecordExcluded = true;
      } else {
        areAllSelectedRecordsExcluded = false;
      }

      if (isItemAddedToEnquiry) {
        isAnySelectedRecordAddedToEnquiry = true;
      }
    });

    return [
      isAnySelectedRecordAddedToEnquiry,
      isAnySelectedRecordExcluded,
      areAllSelectedRecordsExcluded,
    ];
  }, [data, selectedIds]);

  return (
    <Box className={classes.container}>
      {!isAnySelectedRecordExcluded && !isAnySelectedRecordAddedToEnquiry && (
        <AddItemsToEnquiryBulkActionButton {...props} />
      )}
      {!isAnySelectedRecordExcluded && isAnySelectedRecordAddedToEnquiry && (
        <RemoveItemsFromEnquiryBulkActionButton {...props} />
      )}
      {!isAnySelectedRecordExcluded && !isAnySelectedRecordAddedToEnquiry && (
        <ExcludeItemsFromEnquiryBulkActionButton {...props} />
      )}
      {areAllSelectedRecordsExcluded && (
        <IncludeExcludedItemsBulkActionButton {...props} />
      )}
    </Box>
  );
};

export { EnquiryBulkActionButtons };
