import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-final-form";
import { useLocation } from "react-router-dom";
import {
  AutocompleteInput,
  FormDataConsumer,
  ReferenceField,
  ReferenceInput,
  required,
  TextInput,
  TextField,
  RichTextField,
  EmailField,
  useGetOne,
  NumberField,
  RecordContextProvider,
  Identifier,
  ArrayInput,
  SimpleFormIterator,
  Datagrid,
  useRecordContext,
  Button,
} from "react-admin";
import {
  itemResource,
  RESOURCE_NAME_ITEM,
  SingleItemShowContainer,
} from "../../datasetup/items";
import { RESOURCE_NAME_UOM } from "../../datasetup/unit-of-measure";
import {
  RESOURCE_NAME_REQUISITION,
  RESOURCE_NAME_REQUISITION_DETAILS,
} from "../../requisitions";
import { RequisitionSummaryContainer } from "./RequisitionSummaryContainer";
import { RESOURCE_NAME_VENDOR, vendorResource } from "../../datasetup/vendors";
import {
  AccordionField,
  DateTimeInput,
  DefaultList,
  EmptyState,
  MediaInputField,
  SectionTitleSubtitle,
  TruncatedTextField,
} from "../../../lib/components";
import RichTextInput from "ra-input-rich-text";
import { RESOURCE_NAME_CURRENCIES } from "../../datasetup/currencies";
import { validations } from "./validations";
import {
  DEFAULT_DATE_TIME_OPTIONS,
  DEFAULT_PER_PAGE,
  UserFormMessage,
} from "../../../lib/common";
import { embeddedDataGridRowStyle } from "./embeddedDataGridRowStyle";
import { EnquiryBulkActionButtons } from "./EnquiryBulkActionButtons";
import { VendorsBulkActionButtons } from "./VendorsBulkActionButtons";
import {
  ItemListFilters,
  VendorListFilters,
} from "./EnquiryVendorsListFilters";
import { SingleVendorShowContainer } from "../../datasetup/vendors/components";
import { CreateVendorDialogComponent } from "./CreateVendorDialogComponent";
import { EnquiryVendorListActions } from "./EnquiryVendorListActions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemsGrid: {
      "& div:nth-of-type(2) .MuiPaper-root": {
        width: "95vw",
        whiteSpace: "nowrap",
        overflowX: "auto",
      },
    },
    account: { display: "block", width: 544 },

    vendor: { display: "block", width: 544 },
    arrayInput: { display: "flex", flexDirection: "column" },
    formIterator: { form: { display: "flex" } },
    arrayField: { padding: "4px" },
    remarksAccordion: {
      width: "100%",
      marginTop: 25,
    },

    enquiryLayout1: {
      width: "100%",
      display: "grid",
      paddingLeft: 15,
      gridTemplateColumns: "1fr 1fr",
      gridGap: 35,
      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "1fr 1fr",
      },
      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "1fr",
      },
    },
    enquiryLayout2: {
      width: "100%",
      display: "grid",
      paddingLeft: 15,
      paddingTop: 15,
      paddingBottom: 15,
      gridTemplateColumns: "1fr 1fr 1fr",
      gridGap: 15,
      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "1fr 1fr",
      },
      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "1fr",
      },
    },
    emptyStateContainer: {
      width: "100%",
      // display: "flex",
      // flexDirection: "column",
      // alignItems: "flex-start"
    },
    emptyStateIcon: {
      width: "1.25em",
      height: "1.25em",
      alignSelf: "center",
    },
  })
);

const useArrayInputStyles = makeStyles((theme) => ({
  form: {
    display: "grid",
    gridTemplateColumns: "1fr 3fr",
    gridColumnGap: 50,
    [theme.breakpoints.down("sm")]: { gridTemplateColumns: "100%" },
  },
}));

const EnquiryCreateContainer = ({ isEdit = false }) => {
  const record = useRecordContext();
  const classes = useStyles();
  const arrayInputClasses = useArrayInputStyles();
  const form = useForm();
  const queryParams = new URLSearchParams(useLocation().search);
  const requestRequisitionId = queryParams.get("requisitionId");

  const [isVendorQuickCreateDialogOpen, setVendorQuickCreateDialogOpen] =
    useState(false);

  const [selectedRequisitionId, setSelectedRequisitionId] = useState<
    string | undefined
  >();

  const [enquiryDetailsMap, setEnquiryDetailsMap] = useState(new Map());
  const [vendorIds, setVendorIds] = useState<Identifier[]>([]);

  const handleRequisitionLoadSuccess = () => {
    setEnquiryDetailsMap(new Map());
    setVendorIds([]);
    form.change("enquiryDetails", null);
    form.change("vendorIds", null);
  };

  const { data: requisition } = useGetOne(
    RESOURCE_NAME_REQUISITION,
    selectedRequisitionId ?? "",
    {
      onSuccess: handleRequisitionLoadSuccess,
      enabled:
        selectedRequisitionId && selectedRequisitionId?.length ? true : false,
    }
  );

  const handleAddItemsToEnquiry = (map) => {
    const newEnquiriesDetailsMap = new Map(enquiryDetailsMap);
    map.forEach((enquiryDetail, id) =>
      newEnquiriesDetailsMap.set(id, enquiryDetail)
    );

    const enquiryDetails = Array.from(newEnquiriesDetailsMap.values());
    setEnquiryDetailsMap(newEnquiriesDetailsMap);
    if (enquiryDetails?.length) {
      form.change("enquiryDetails", enquiryDetails);
    } else {
      form.change("enquiryDetails", null);
    }
  };

  const handleRemoveItemsFromEnquiry = (ids: Array<Identifier>) => {
    const newEnquiriesDetailsMap = new Map(enquiryDetailsMap);

    ids.forEach((id) => newEnquiriesDetailsMap.delete(id));

    const enquiryDetails = Array.from(newEnquiriesDetailsMap.values());
    setEnquiryDetailsMap(newEnquiriesDetailsMap);
    if (enquiryDetails?.length) {
      form.change("enquiryDetails", enquiryDetails);
    } else {
      form.change("enquiryDetails", null);
    }
  };

  const hasEnquiryDetailsId = (id: Identifier) => {
    return enquiryDetailsMap.has(id);
  };

  const hasVendorId = (id: Identifier) => {
    return vendorIds.includes(id.toString());
  };

  const handleAddVendorsToEnquiry = (selectedIds: Array<Identifier>) => {
    let newVendorIds: Identifier[] = vendorIds;

    selectedIds?.forEach((id) => {
      const newRecordIndex = vendorIds.indexOf(id);

      if (newRecordIndex < 0) {
        newVendorIds.push(id);
      }
    });

    setVendorIds(newVendorIds);

    if (newVendorIds && newVendorIds.length) {
      form.change("vendorIds", newVendorIds);
    } else {
      form.change("vendorIds", null);
    }
  };

  const handleRemoveVendorsFromEnquiry = (selectedIds: Array<Identifier>) => {
    let newVendorIds: Identifier[] = [];

    selectedIds?.forEach((id) => {
      const newRecordIndex = vendorIds.indexOf(id);

      if (newRecordIndex >= 0) {
        //entry already exists during toggled on, remove it
        newVendorIds = [
          ...vendorIds.splice(0, newRecordIndex),
          ...vendorIds.splice(newRecordIndex + 1),
        ];
      }
    });

    setVendorIds(newVendorIds);

    if (newVendorIds && newVendorIds.length) {
      form.change("vendorIds", newVendorIds);
    } else {
      form.change("vendorIds", null);
    }
  };

  const handleQuickVendorCreateClick = () => {
    setVendorQuickCreateDialogOpen(true);
  };

  const handleVendorQuickCreateDialogClose = () => {
    setVendorQuickCreateDialogOpen(false);
  };

  useEffect(() => {
    if (requestRequisitionId) {
      form.change("requisitionId", requestRequisitionId);
      setSelectedRequisitionId(requestRequisitionId);
    }
  }, [requestRequisitionId]);

  useEffect(() => {
    if (requisition) {
      form.change("showUnitPrice2", requisition?.showUnitPrice2);
    }
  }, [requisition]);

  return (
    <>
      {!requestRequisitionId && (
        <ReferenceInput
          label="resources.enquiries.fields.requisitionId"
          source="requisitionId"
          reference={RESOURCE_NAME_REQUISITION}
          filter={{
            openRequisitions: true,
          }}
          validate={[required()]}
        >
          <AutocompleteInput
            optionText="displayLabel"
            helperText="resources.enquiries.help.requisitionId"
            isRequired
            resettable={true}
            allowEmpty={false}
          />
        </ReferenceInput>
      )}

      {!requisition && (
        <UserFormMessage
          line1="resources.enquiries.messages.openRequisitions.line1"
          line2="resources.enquiries.messages.openRequisitions.line2"
        />
      )}
      {requisition && (
        <RecordContextProvider
          value={{
            id: requisition.id,
            requisitionId: requisition.id,
          }}
        >
          <RequisitionSummaryContainer />
        </RecordContextProvider>
      )}
      {requisition && (
        <SectionTitleSubtitle
          title="resources.enquiries.sections.enquiry.input.title"
          subTitle="resources.enquiries.sections.enquiry.input.subTitle"
        />
      )}
      {requisition && (
        <Box className={classes.enquiryLayout1}>
          <TextInput
            source="code"
            helperText="resources.enquiries.help.code"
            isRequired
            validate={[required()]}
          />
          <ReferenceInput
            label="resources.enquiries.fields.currencyId"
            source="currencyId"
            reference={RESOURCE_NAME_CURRENCIES}
            validate={validations.code}
          >
            <AutocompleteInput
              optionText="displayLabel"
              helperText="resources.enquiries.help.currencyId"
            />
          </ReferenceInput>
        </Box>
      )}
      {requisition && (
        <Box className={classes.enquiryLayout2}>
          <DateTimeInput
            label="resources.enquiries.fields.bidStartDate"
            source="bidStartDate"
            helperText="resources.enquiries.help.bidStartDate"
            validate={validations.bidStartDate}
            options={DEFAULT_DATE_TIME_OPTIONS}
            isRequired
          />

          <DateTimeInput
            label="resources.enquiries.fields.bidEndDate"
            source="bidEndDate"
            helperText="resources.enquiries.help.bidEndDate"
            validate={validations.bidEndDate}
            options={DEFAULT_DATE_TIME_OPTIONS}
            isRequired
          />

          <DateTimeInput
            label="resources.enquiries.fields.preferredDeliveryDate"
            source="preferredDeliveryDate"
            helperText="resources.enquiries.help.preferredDeliveryDate"
            validate={validations.preferredDeliveryDate}
            options={DEFAULT_DATE_TIME_OPTIONS}
          />
        </Box>
      )}
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          if (formData?.requisitionId) {
            setSelectedRequisitionId(formData?.requisitionId);
          }
          return <div />;
        }}
      </FormDataConsumer>

      {requisition && (
        <Fragment>
          <SectionTitleSubtitle
            title="resources.enquiries.sections.items.input.title"
            subTitle="resources.enquiries.sections.items.input.subTitle"
          />
          <DefaultList
            resource={RESOURCE_NAME_REQUISITION_DETAILS}
            basePath="/"
            exporter={false}
            perPage={DEFAULT_PER_PAGE}
            title="Enquiries"
            showBreadcrumbs={false}
            sort={{ field: "sortOrder", order: "ASC" }}
            filters={<ItemListFilters />}
            filter={{
              requisitionId: requisition.id,
              openRequisitionDetailsForEnquiry: null,
              openRequisitions: true,
            }}
            syncWithLocation={false}
            listRowActions={false}
            actions={false}
            bulkActionButtons={
              <EnquiryBulkActionButtons
                handleAddItemsToEnquiry={handleAddItemsToEnquiry}
                handleRemoveItemsFromEnquiry={handleRemoveItemsFromEnquiry}
                enquiryDetailsMap={enquiryDetailsMap}
              />
            }
            empty={
              <EmptyState
                title="resources.enquiries.sections.items.emptyState"
                subTitle={null}
                Icon={itemResource.icon}
                className={classes.emptyStateContainer}
                iconClassName={classes.emptyStateIcon}
              />
            }
            className={classes.itemsGrid}
          >
            <Datagrid
              rowStyle={embeddedDataGridRowStyle(hasEnquiryDetailsId)}
              expand={
                <SingleItemShowContainer
                  procureAccountId={record?.procureAccountId}
                />
              }
            >
              <TextField
                source="sortOrder"
                label="resources.enquiries.fields.serialNum"
              />
              <ReferenceField
                label="resources.enquiries.fields.number"
                source="itemId"
                reference={RESOURCE_NAME_ITEM}
                link={false}
              >
                <TruncatedTextField source="number" truncateLength={250} />
              </ReferenceField>
              <ReferenceField
                label="resources.enquiries.fields.itemId"
                source="itemId"
                reference={RESOURCE_NAME_ITEM}
                link={false}
              >
                <TruncatedTextField source="name" truncateLength={250} />
              </ReferenceField>

              <NumberField source="quantity" />

              <ReferenceField
                label="resources.enquiries.fields.uomId"
                source="uomId"
                reference={RESOURCE_NAME_UOM}
                link={false}
              >
                <TextField source="displayLabel" />
              </ReferenceField>

              {requisition?.showUnitPrice2 && (
                <NumberField source="quantity2" />
              )}

              {requisition?.showUnitPrice2 && (
                <ReferenceField
                  label="resources.enquiries.fields.uom2Id"
                  source="uom2Id"
                  reference={RESOURCE_NAME_UOM}
                  link={false}
                >
                  <TextField source="displayLabel" />
                </ReferenceField>
              )}
              <TruncatedTextField
                source="make"
                label="resources.enquiries.fields.make"
                truncateLength={25}
              />
              <TruncatedTextField
                source="size"
                label="resources.enquiries.fields.size"
                truncateLength={25}
              />
              <RichTextField
                source="specifications"
                label="resources.enquiries.fields.specifications"
              />
              <TruncatedTextField
                source="comments"
                label="resources.enquiries.fields.comments"
                truncateLength={25}
              />
            </Datagrid>
          </DefaultList>
        </Fragment>
      )}

      {requisition && (
        <Fragment>
          <SectionTitleSubtitle
            title="resources.enquiries.sections.vendors.input.title"
            subTitle="resources.enquiries.sections.vendors.input.subTitle"
          />
          <DefaultList
            resource={RESOURCE_NAME_VENDOR}
            basePath="/"
            exporter={false}
            perPage={DEFAULT_PER_PAGE}
            title="Vendors"
            showBreadcrumbs={false}
            sort={{ field: "id", order: "ASC" }}
            filters={<VendorListFilters />}
            syncWithLocation={false}
            listRowActions={false}
            actions={
              <EnquiryVendorListActions
                handleQuickVendorCreateClick={handleQuickVendorCreateClick}
              />
            }
            bulkActionButtons={
              <VendorsBulkActionButtons
                handleAddVendorsToEnquiry={handleAddVendorsToEnquiry}
                handleRemoveVendorsFromEnquiry={handleRemoveVendorsFromEnquiry}
              />
            }
            empty={
              <EmptyState
                title="resources.enquiries.sections.vendors.emptyState"
                subTitle={null}
                Icon={vendorResource.icon}
                className={classes.emptyStateContainer}
                iconClassName={classes.emptyStateIcon}
              />
            }
          >
            <Datagrid
              rowStyle={embeddedDataGridRowStyle(hasVendorId)}
              expand={<SingleVendorShowContainer />}
            >
              <TextField
                source="displayLabel"
                label="resources.enquiries.fields.vendorAccountName"
                sortable={false}
              />
              <TextField
                source="vendorAccount.primaryUser.fullName"
                label="resources.enquiries.fields.vendorPrimaryUserName"
                sortable={false}
              />
              <EmailField
                source="vendorAccount.primaryUser.email"
                label="resources.enquiries.fields.vendorPrimaryUserEmail"
                sortable={false}
              />
            </Datagrid>
          </DefaultList>
        </Fragment>
      )}

      {requisition && (
        <AccordionField
          headerlabel="resources.enquiries.sections.conditions.input.title"
          headerTooltip="resources.enquiries.sections.conditions.input.tooltip"
          className={classes.remarksAccordion}
        >
          <Box>
            <SectionTitleSubtitle title="resources.enquiries.sections.conditions.input.heading" />
            <ArrayInput source="conditions" label={false}>
              <SimpleFormIterator classes={arrayInputClasses}>
                <TextInput
                  source="conditionType"
                  label="resources.enquiries.fields.conditionType"
                  helperText="resources.enquiries.help.conditionType"
                  resettable={true}
                  validate={[required()]}
                />
                <TextInput
                  multiline
                  fullWidth={true}
                  source="condition"
                  label="resources.enquiries.fields.condition"
                  helperText="resources.enquiries.help.condition"
                  resettable={true}
                  validate={[required()]}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Box>
        </AccordionField>
      )}

      {requisition && (
        <AccordionField
          headerlabel="resources.enquiries.sections.remarks.input.title"
          headerTooltip="resources.enquiries.sections.remarks.input.tooltip"
          className={classes.remarksAccordion}
        >
          <RichTextInput
            source="remarks"
            helperText="resources.bids.help.remarks"
            label={false}
          />
        </AccordionField>
      )}
      {requisition && (
        <AccordionField
          headerlabel="resources.enquiries.sections.fileUpload.input.title"
          headerTooltip="resources.enquiries.sections.fileUpload.input.tooltip"
        >
          <Box display="flex" flexDirection="column" width="100%">
            <MediaInputField showUploadedFiles={isEdit} />
          </Box>
        </AccordionField>
      )}
      {isVendorQuickCreateDialogOpen && (
        <CreateVendorDialogComponent
          isDialogOpen={isVendorQuickCreateDialogOpen}
          handleDialogClose={handleVendorQuickCreateDialogClose}
        />
      )}
    </>
  );
};

export { EnquiryCreateContainer };
