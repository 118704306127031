import { CreateProps } from "react-admin";
import { DefaultCreate } from "../../../../lib/components";
import {
  MediaFileSource,
  transformAndUploadS3Files,
} from "../../../../lib/modules";
import { ItemForm } from "./components/ItemForm";

const ItemCreate = (props: CreateProps) => {
  const mediaFileSources: MediaFileSource[] = [
    {
      source: "files",
      subFolder: "items",
    },
  ];

  return (
    <DefaultCreate
      {...props}
      transform={transformAndUploadS3Files(mediaFileSources)}
    >
      <ItemForm isEdit={false} />
    </DefaultCreate>
  );
};

export { ItemCreate };
