import { ShowContextProvider, useShowController } from "react-admin"
import { RESOURCE_NAME_ENQUIRY } from "../../enquiries"
import { EnquiryShowContainer } from "../../enquiries/components"

const BidEnquiryShowContainer = (props) => {
  const controllerProps = useShowController({
    ...props,
    id: props?.record?.enquiryId,
    resource: RESOURCE_NAME_ENQUIRY,
  })

  return (
    <ShowContextProvider value={controllerProps}>
      <EnquiryShowContainer />
    </ShowContextProvider>
  )
}

export { BidEnquiryShowContainer }
