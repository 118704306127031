import {
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  useMediaQuery,
  useTheme,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { TransitionProps } from "@material-ui/core/transitions";
import { forwardRef, ReactElement, Ref, useState } from "react";
import {
  Create,
  useCreateSuggestionContext,
  useNotify,
  useTranslate,
} from "react-admin";
import {
  MediaFileSource,
  transformAndUploadS3Files,
} from "../../../lib/modules";
import { ItemForm, RESOURCE_NAME_ITEM } from "../../datasetup/items/items";

const mediaFileSources: MediaFileSource[] = [
  {
    source: "files",
    subFolder: "items",
  },
];

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children?: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateNewItem = () => {
  const translate = useTranslate();
  const theme = useTheme();
  const isMdOrGreater = useMediaQuery(theme?.breakpoints?.up("md"));

  const [open, setOpen] = useState(true);
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const notify = useNotify();

  const onSuccess = ({ data }) => {
    notify("resources.items.messages.created", { type: "success" });
    onCreate(data);
    setOpen(false);
  };

  const onFailure = (error) => {
    if (error.code === 123) {
      notify("Could not save changes: concurrent edition in progress", {
        type: "error",
      });
    } else {
      const errorMessage = `An error occured and your changes were not saved. \n ${
        error?.message || "ra.notification.http_error"
      }`;
      notify(errorMessage, { type: "error", multiLine: true });
    }
  };

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      TransitionComponent={Transition}
      fullWidth={isMdOrGreater ? true : false}
      maxWidth={isMdOrGreater ? "lg" : false}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" disableTypography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="left"
            alignItems="start"
          >
            <Typography variant="h6">
              {translate("resources.items.dialog.title.header")}
            </Typography>
            <Typography variant="caption">
              {translate("resources.items.dialog.title.caption")}
            </Typography>
          </Box>
          <IconButton aria-label="close" onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Create
          resource={RESOURCE_NAME_ITEM}
          basePath={`/${RESOURCE_NAME_ITEM}/create`}
          transform={transformAndUploadS3Files(mediaFileSources)}
          onSuccess={onSuccess}
          onFailure={onFailure}
          record={{ number: filter }}
        >
          <ItemForm isEdit={false} />
        </Create>
      </DialogContent>
    </Dialog>
  );
};

export { CreateNewItem };
