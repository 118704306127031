import { Button, TopToolbar } from "react-admin";
import { RefetchIconButton } from "../../../lib/components";
import { usePermissionsCheck } from "../../../lib/core";
import { vendorResource } from "../../datasetup/vendors";

const EnquiryVendorListActions = (props) => {
    const { handleQuickVendorCreateClick, ...rest } = props;
    const { hasPermissionAccess} = usePermissionsCheck();
    const canCreateVendor = hasPermissionAccess(vendorResource?.options?.allowedPermissions?.create);
    return (
        <TopToolbar {...rest}>
            <RefetchIconButton />
            {canCreateVendor && <Button label="Create Vendor" onClick={handleQuickVendorCreateClick} />}
        </TopToolbar>
    )
}

export { EnquiryVendorListActions }