const Footer = () => (
    <div
      style={{
        position: "fixed",
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 100,
        padding: 6,
        backgroundColor: "#283593",
        fontSize: "small",
      }}
      id="hideOnPrint"
    >
      <span
        style={{
          color: "#ffffff",
          textAlign: "left",
          paddingLeft: 6,
        }}
      >
        Copyright © 2022 - Prosyms
      </span>
  
      <span
        style={{
          textAlign: "right",
          color: "#ffffff",
          float: "right",
          paddingRight: 6,
        }}
      >
        Powered by
        <a
          href="https://synergenie.com"
          target="_blank"
          rel="noreferrer"
          style={{
            color: "#FBBA72",
            textDecoration: "none",
            paddingLeft: 6,
          }}
        >
          Synergenie
        </a>
      </span>
    </div>
  );
  
  export { Footer };
  