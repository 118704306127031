import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setSidebarVisibility,
  ShowProps,
  Tab,
  TabbedShowLayout,
} from "react-admin";
import { DefaultShow } from "../../lib/components";

import { RequisitionShowContainer } from "./components/RequisitionShowContainer";
import { RequisitionShowEnquiries } from "./components/RequisitionShowEnquiries";
const RequisitionShow = (props: ShowProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSidebarVisibility(false));
    // eslint-disable-next-line
  }, []);

  return (
    <DefaultShow {...props}>
      <TabbedShowLayout {...props}>
        <Tab label="resources.requisitions.fieldGroups.requisitionTab">
          <RequisitionShowContainer {...props} />
        </Tab>
        <Tab label="resources.enquiries.fieldGroups.enquiry">
          <RequisitionShowEnquiries />
        </Tab>
      </TabbedShowLayout>
    </DefaultShow>
  );
};

export { RequisitionShow };
