import { Fragment, useState } from "react";
import { Button, ButtonProps, Confirm, ConfirmProps } from "react-admin";

export interface ConfirmButtonProps extends ButtonProps {
  confirm: ConfirmProps;
}

const ConfirmButton = (props: ConfirmButtonProps) => {
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const { confirm, ...rest } = props;

  const handleClick = (e) => {
    setConfirmOpen(true);
    e.stopPropagation();
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  }

  const handleOnConfirm = (event) => {
    handleConfirmClose();
    confirm?.onConfirm(event);
  }

  return (
    <Fragment>
      <Button {...rest} onClick={handleClick} />
      <Confirm
        {...confirm}
        isOpen={isConfirmOpen}
        onConfirm={handleOnConfirm}
        onClose={handleConfirmClose}
      />
    </Fragment>
  );
};

export { ConfirmButton };
