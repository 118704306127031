import { DateField, DateFieldProps, useRecordContext } from "react-admin";
import get from "lodash/get";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { Box } from "@material-ui/core";
import TimerIcon from "@material-ui/icons/TimerTwoTone";

interface DateCountdownFieldProps extends DateFieldProps {}

const CompletedTime = () => <div>Expired</div>;
const renderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}: CountdownRenderProps) => {
  return (
    <Box
      display="grid"
      gridAutoFlow="column"
      gridColumnGap="3px"
      justifyContent="start"
      alignItems="center"
    >
      <TimerIcon />
      {completed && <CompletedTime />}
      {!completed && `${days}d ${hours}h ${minutes}m ${seconds}s`}
    </Box>
  );
};

const DateCountdownField = (props: DateCountdownFieldProps) => {
  const record = useRecordContext(props);
  if (!record) {
    return null;
  }

  const value = props?.source ? get(record, props?.source) : "";

  if (!value || value == null || value === "") {
    return null;
  }

  return (
    <Box display="grid" gridAutoFlow="row" gridRowGap="10px">
      <DateField {...props} emptyText="N/A" />
      <Countdown date={value} renderer={renderer} />
    </Box>
  );
};

export { DateCountdownField };
