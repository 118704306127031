import {
  Box,
  createStyles,
  FormControl,
  makeStyles,
  Theme,
  TextField,
  Typography,
} from "@material-ui/core";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "450px",
      height: "300px",
    },
    subTitle2: {
      paddingTop: 15,
    },
    exclusionReason: {
      margin: theme.spacing(1),
      width: "95%",
    },
  })
);

const EnquiryBulkActionButtonsForm = (props) => {
  const classes = useStyles();
  const translate = useTranslate();

  const storeRejectionReason = (event) => {
      if(props?.setExclusionReason){
          props?.setExclusionReason(event.target.value);
      }
  };

  return (
    <Box className={classes.container}>
      <Typography variant="subtitle1" color="secondary" gutterBottom={true}>
        {translate(
          "resources.enquiries.actions.excludeItemsFromEnquiry.message"
        )}
      </Typography>
      <Typography variant="subtitle1" color="textSecondary" className={classes.subTitle2}>
        {translate("resources.enquiries.actions.excludeItemsFromEnquiry.subText")}
      </Typography>
      <FormControl className={classes.exclusionReason}>
        <TextField
          multiline
          variant="outlined"
          rows={3}
          onChange={storeRejectionReason}
          label={translate("resources.enquiries.actions.excludeItemsFromEnquiry.exclusionReason.label")}
          helperText={translate("resources.enquiries.actions.excludeItemsFromEnquiry.exclusionReason.helper")}
        />
      </FormControl>
    </Box>
  );
};

export { EnquiryBulkActionButtonsForm };
