import { ReferenceInput, AutocompleteInput } from "ra-ui-materialui";
import { MAX_RECORDS_PER_PAGE } from "../../../lib/common";
import { itemResource, RESOURCE_NAME_ITEM } from "../../datasetup/items";
import { ORG_STRUCTURE_USER_GROUP_TYPE } from "../../datasetup/org-structure";
import { useForm } from "react-final-form";
import { useGetOne, Identifier, required } from "react-admin";
import { useEffect, useState } from "react";
import { CreateNewItem } from "./CreateNewItem";
import { usePermissionsCheck } from "../../../lib/core";

const RequisitionItemInputField = (props) => {
  const { getSource, existingItemIds, setExistingItemIds, isEdit, ...rest } =
    props;
  const [currentItemId, setCurrentItemId] = useState<Identifier>();

  const { data: currentItem, loaded: currentItemLoaded } = useGetOne(
    RESOURCE_NAME_ITEM,
    currentItemId || "",
    {
      enabled: currentItemId ? true : false,
    }
  );
  const form = useForm();
  const { hasPermissionAccess, permissionsLoaded } = usePermissionsCheck();
  const [shouldAllowCreate, setShouldAllowCreate] = useState(false);

  useEffect(() => {
    if (currentItem && currentItemLoaded) {
      processItemId();
    }
  }, [currentItemLoaded, currentItem]);

  useEffect(() => {
    if (permissionsLoaded) {
      setShouldAllowCreate(
        hasPermissionAccess(itemResource?.options?.allowedPermissions?.create)
      );
    }
  }, [permissionsLoaded]);

  const handleItemIdChanged = (itemId) => {
    setCurrentItemId(itemId);
  };

  const processItemId = () => {
    if (!currentItem) {
      return;
    }
    const isEdit = props?.isEdit || false;

    const requisitionDetails = form.getState()?.values?.requisitionDetails;
    const itemIds = requisitionDetails
      ?.map((record) => record?.itemId)
      ?.filter((itemId) => itemId && itemId !== "");

    if (itemIds && itemIds?.length) {
      setExistingItemIds(new Set(itemIds));
    }

    const currentItemId = currentItem?.id;
    const uomId = currentItem?.uomId;
    const size = currentItem?.size?.trim();
    const specifications = currentItem?.specifications?.trim();

    const newRequisitionDetails = requisitionDetails?.map((record, index) => {
      const recordItemId = record?.itemId;
      const isMatchingItemId = currentItemId === recordItemId;
      const hasRecordUomId = record?.uomId?.length > 0;
      const hasRecordSize = record?.size?.length > 0;
      const hasRecordSpecifications = record?.specifications?.length > 0;
      const isEmptyRecord =
        isMatchingItemId &&
        !hasRecordUomId &&
        !hasRecordSize &&
        !hasRecordSpecifications;

      if (!record || !isMatchingItemId || !isEmptyRecord) {
        return record;
      } else if (isEmptyRecord) {
        if (uomId && uomId.length) {
          record["uomId"] = uomId;
        } else {
          delete record["uomId"];
        }

        if (size && size.length) {
          record["size"] = size;
        } else {
          delete record["size"];
        }

        if (specifications && specifications.length) {
          record["specifications"] = specifications;
        } else {
          delete record["specifications"];
        }

        return record;
      }
    });
    form.change("requisitionDetails", newRequisitionDetails);
  };

  if (isEdit && !existingItemIds) {
    return null;
  }

  return (
    <ReferenceInput
      {...rest}
      source={getSource("itemId")}
      reference={RESOURCE_NAME_ITEM}
      label="resources.requisitions.fields.itemId"
      validate={[required()]}
      resettable
      filter={{
        orgStructureType: ORG_STRUCTURE_USER_GROUP_TYPE.REQUISITIONS,
      }}
      sort={{ field: "number", order: "ASC" }}
      perPage={MAX_RECORDS_PER_PAGE}
      onChange={handleItemIdChanged}
    >
      <AutocompleteInput
        optionText="displayLabel"
        helperText="resources.requisitions.help.itemId"
        isRequired
        clearAlwaysVisible
        resettable
        suggestionLimit={20}
        allowEmpty={false}
        createLabel="resources.items.messages.createNewItem"
        createItemLabel="resources.items.messages.createNewItem"
        create={shouldAllowCreate ? <CreateNewItem /> : undefined}
      />
    </ReferenceInput>
  );
};

export { RequisitionItemInputField };
