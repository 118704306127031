import { Record } from "react-admin";
import { DatagridColors } from "../../enquiries";

const requisitionDatagridRowStyle = (record: Record, index: number) => {
    const id = record?.id;
    const isExcluded = record?.isExcluded;
    const isOpen = record?.isOpen;
    const hasEnquiryId = record?.enquiryId;

    let backgroundColor = DatagridColors.UNSELECTED_BG_COLOR;
    let color = DatagridColors.UNSELECTED_COLOR;

    if (isExcluded){
        backgroundColor = DatagridColors.EXCLUDED_BG_COLOR;
        color = DatagridColors.EXCLUDED_COLOR;
      } else if (!isOpen && hasEnquiryId) {
        backgroundColor = DatagridColors.SELECTED_BG_COLOR;
        color = DatagridColors.SELECTED_COLOR;
      }
    
      return {
        color,
        backgroundColor,
        transition: "background-color 0.5s",
      };
}

export { requisitionDatagridRowStyle };