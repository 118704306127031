import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { forwardRef } from "react";
import { Create, useNotify, useTranslate } from "react-admin";
import {
  MediaFileSource,
  transformAndUploadS3Files,
} from "../../../lib/modules";
import { RESOURCE_NAME_VENDOR } from "../../datasetup/vendors";
import { VendorForm } from "../../datasetup/vendors/components/VendorForm";
import CloseIcon from "@material-ui/icons/Close";

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateVendorDialogComponent = (props) => {
  const { isDialogOpen, handleDialogClose, ...rest } = props;
  const notify = useNotify();
  const translate = useTranslate();
  const theme = useTheme();
  const isMdOrGreater = useMediaQuery(theme?.breakpoints?.up("md"));

  const mediaFileSources: MediaFileSource[] = [
    {
      source: "files",
      subFolder: "vendors",
    },
  ];

  const onSuccess = () => {
    notify("resources.enquiries.messages.quickVendorCreate.success", {
      type: "success",
    });
    handleDialogClose();
  };

  const onFailure = (error) => {
    const message = translate(
      "resources.enquiries.messages.quickVendorCreate.failure",
      { serverError: error?.message }
    );
    notify(message, { type: "error", multiLine: true });
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleDialogClose}
      TransitionComponent={Transition}
      fullWidth={isMdOrGreater ? true : false}
      maxWidth={isMdOrGreater ? "lg" : false}
      aria-labelledby="ra-columns-dialog-title"
    >
      <DialogTitle id="form-dialog-title" disableTypography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="left"
            alignItems="start"
          >
            <Typography variant="h6">
              {translate("resources.enquiries.messages.quickVendorCreate.dialog.title.header")}
            </Typography>
            <Typography variant="caption">
              {translate("resources.enquiries.messages.quickVendorCreate.dialog.title.caption")}
            </Typography>
          </Box>
          <IconButton aria-label="close" onClick={handleDialogClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Create
          {...rest}
          transform={transformAndUploadS3Files(mediaFileSources)}
          basePath={`${RESOURCE_NAME_VENDOR}`}
          resource={RESOURCE_NAME_VENDOR}
          onSuccess={onSuccess}
          onFailure={onFailure}
        >
          <VendorForm />
        </Create>
      </DialogContent>
    </Dialog>
  );
};

export { CreateVendorDialogComponent };
