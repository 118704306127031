import { useState } from "react";
import { useFormState } from "react-final-form";
import {
  TextInput,
  required,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  useTranslate,
  Identifier,
  FormDataConsumer,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

import { Box, Typography, useMediaQuery } from "@material-ui/core";

import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { RESOURCE_NAME_UOM } from "../datasetup/unit-of-measure";

import {
  AccordionField,
  MediaInputField,
  SectionTitleSubtitle,
} from "../../lib/components";
import { RESOURCE_NAME_BUSINESS_FUNCTIONS } from "../datasetup/business-functions/constants";
import { CommentsAccordion, OwnerEntityTypes } from "../comments";
import { validations } from "./components/validations";
import { ItemDetailsInputAccordionField } from "../../common";
import { RequisitionItemInputField } from "./components/RequisitionItemInputField";

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "row",
      padding: 0,
      gap: 10,
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
      "& .MuiAccordionSummary-content": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        columnGap: 10,
        width: "100%",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
          justifyContent: "flex-start",
          alignSelf: "flex-start",
          marginTop: 25,
        },
      },
      "& .MuiAccordionSummary-content >.ra-input-itemId": {
        paddingRight: 15,
      },
      "& .MuiAccordionSummary-expandIcon": {
        transform: "rotate(-90deg)",
        alignSelf: "center",
        position: "absolute",
        left: -60,
        [theme.breakpoints.down("sm")]: {
          alignSelf: "flex-start",
          left: -5,
          top: -10,
        },
        "& .Mui-expanded": {
          transform: "rotate(1deg)",
        },
      },
      "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
        transform: "rotate(1deg)",
      },
      "& .ql-editor": {
        backgroundColor: "#f1e3f4",
      },
    },
    itemId: {
      width: 350,
      [theme.breakpoints.down("sm")]: {},
    },
    code: { display: "block" },
    description: {
      display: "block",
    },
    activityContainer: {
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },

    businessFunctionId: {
      flexGrow: "unset",
      display: "flex",
      width: "max-content",
    },

    field1: {
      display: "inline-block",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    field2: {
      display: "inline-block",
      marginLeft: theme.spacing(5),
      [theme.breakpoints.down("sm")]: {
        display: "block",
        marginLeft: 0,
      },
    },
    field3: {
      display: "inline-block",
      marginLeft: theme.spacing(5),
      [theme.breakpoints.down("sm")]: {
        display: "block",
        marginLeft: 0,
      },
    },
    formIterator: { form: { display: "flex" } },
    remarksAccordion: { width: "100%" },
    requisitionDetailSection: {
      marginTop: 25,
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      "& div": {
        paddingLeft: "unset",
        paddingBottom: "unset",
      },
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
        flexDirection: "column",
        rowGap: 15,
      },
    },
    quantity2: {
      width: "max-content",
    },
    itemsArrayContainer: {
      overflowX: "auto",
      whiteSpace: "nowrap",
      width: "90vw",
      "& li": {
        flexDirection: "row",
        [theme.breakpoints.down("sm")]: { flexDirection: "column" },
      },
    },
  })
);

const RequisitionForm = (props) => {
  const { isEdit, savedItemIds, ...rest } = props;
  const { initialValues } = useFormState();
  const classes = useStyles(rest);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [existingItemIds, addExistingItemIds] = useState<Set<Identifier>>(
    new Set(savedItemIds || [])
  );

  const showRequisitionDetails = !isEdit
    ? true
    : initialValues?.requisitionDetails?.length > 0;

  return (
    <>
      <SectionTitle label="resources.requisitions.fieldGroups.requisition" />
      <TextInput
        autoFocus
        source="code"
        label="resources.requisitions.fields.code"
        helperText="resources.requisitions.help.code"
        formClassName={classes.code}
        validate={validations.code}
      />
      <RichTextInput
        source="description"
        label="resources.requisitions.fields.description"
        helperText="resources.requisitions.help.description"
        className={classes.description}
      />
      <Box className={classes.activityContainer}>
        <ReferenceInput
          label="resources.requisitions.fields.businessFunction"
          source="businessFunctionId"
          reference={RESOURCE_NAME_BUSINESS_FUNCTIONS}
          className={classes.businessFunctionId}
        >
          <AutocompleteInput
            optionText="displayLabel"
            helperText="resources.requisitions.help.businessFunction"
            resettable={true}
          />
        </ReferenceInput>
      </Box>
      <TextInput
        source="field1"
        label="resources.requisitions.fields.field1"
        helperText="resources.requisitions.help.field1"
        className={classes.field1}
      />

      <TextInput
        source="field2"
        label="resources.requisitions.fields.field2"
        helperText="resources.requisitions.help.field2"
        className={classes.field2}
      />

      <TextInput
        source="field3"
        label="resources.requisitions.fields.field3"
        helperText="resources.requisitions.help.field3"
        className={classes.field3}
      />

      <Box className={classes.requisitionDetailSection}>
        <SectionTitleSubtitle
          title="resources.requisitions.fieldGroups.requisitionDetails.title"
          subTitle="resources.requisitions.fieldGroups.requisitionDetails.subTitle"
        />
        <BooleanInput
          label="resources.requisitions.fields.showUnitPrice2"
          source="showUnitPrice2"
        />
      </Box>

      {showRequisitionDetails && (
        <Box>
          <ArrayInput
            source="requisitionDetails"
            isRequired
            validate={[required()]}
            label={false}
            margin="dense"
          >
            <SimpleFormIterator
              margin="dense"
              variant="filled"
              className={classes.itemsArrayContainer}
            >
              <FormDataConsumer>
                {({ getSource, formData, scopedFormData, ...rest }) => {
                  if (!getSource) {
                    return <div />;
                  }
                  const showUnitPrice2 = formData?.showUnitPrice2;
                  return (
                    <ItemDetailsInputAccordionField
                      record={scopedFormData}
                      summaryClassName={classes.form}
                    >
                      <RequisitionItemInputField
                        getSource={getSource}
                        existingItemIds={existingItemIds}
                        setExistingItemIds={addExistingItemIds}
                        isEdit={isEdit}
                        {...rest}
                      />

                      <NumberInput
                        {...rest}
                        source={getSource("quantity")}
                        label="resources.requisitions.fields.quantity"
                        helperText="resources.requisitions.help.quantity"
                        validate={[required()]}
                        fullWidth={isSmall}
                      />

                      <ReferenceInput
                        {...rest}
                        source={getSource("uomId")}
                        label="resources.requisitions.fields.uomId"
                        reference={RESOURCE_NAME_UOM}
                        validate={[required()]}
                      >
                        <AutocompleteInput
                          helperText="resources.requisitions.help.uomId"
                          e
                          optionText="displayLabel"
                          isRequired
                          fullWidth={isSmall}
                        />
                      </ReferenceInput>
                      {showUnitPrice2 && (
                        <NumberInput
                          {...rest}
                          source={getSource("quantity2")}
                          label="resources.requisitions.fields.quantity2"
                          helperText="resources.requisitions.help.quantity2"
                          className={classes.quantity2}
                          fullWidth={isSmall}
                        />
                      )}
                      {showUnitPrice2 && (
                        <ReferenceInput
                          {...rest}
                          source={getSource("uom2Id")}
                          reference={RESOURCE_NAME_UOM}
                          label="resources.requisitions.fields.uom2Id"
                          className={classes.quantity2}
                        >
                          <AutocompleteInput
                            helperText="resources.requisitions.help.uom2Id"
                            optionText="displayLabel"
                            fullWidth={isSmall}
                          />
                        </ReferenceInput>
                      )}
                      <TextInput
                        {...rest}
                        source={getSource("make")}
                        label="resources.requisitions.fields.make"
                        helperText="resources.requisitions.help.make"
                        fullWidth={isSmall}
                      />

                      <TextInput
                        {...rest}
                        source={getSource("size")}
                        label="resources.requisitions.fields.size"
                        helperText="resources.requisitions.help.size"
                        fullWidth={isSmall}
                      />
                      <RichTextInput
                        {...rest}
                        source={getSource("specifications")}
                        label="resources.requisitions.fields.specifications"
                        helperText="resources.requisitions.help.specifications"
                        fullWidth={isSmall}
                      />

                      <TextInput
                        {...rest}
                        source={getSource("comments")}
                        label="resources.requisitions.fields.comments"
                        helperText="resources.requisitions.help.comments"
                        fullWidth={isSmall}
                      />
                    </ItemDetailsInputAccordionField>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </Box>
      )}

      <AccordionField
        headerlabel="resources.requisitions.sections.remarks.input.title"
        headerTooltip="resources.requisitions.sections.remarks.input.tooltip"
        className={classes.remarksAccordion}
      >
        <RichTextInput
          source="remarks"
          helperText="resources.requisitions.help.remarks"
          label={false}
        />
      </AccordionField>
      <AccordionField
        headerlabel="resources.requisitions.sections.fileUpload.input.title"
        headerTooltip="resources.requisitions.sections.fileUpload.input.tooltip"
        className={classes.remarksAccordion}
      >
        <Box display="flex" flexDirection="column" width="100%">
          <MediaInputField showUploadedFiles={isEdit} />
        </Box>
      </AccordionField>

      {isEdit && (
        <CommentsAccordion
          ownerEntityType={OwnerEntityTypes.REQUISITION}
          ownerEntityId={rest?.id}
          isViewOnly={false}
        />
      )}
    </>
  );
};

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return <Typography variant="h6">{translate(label)}</Typography>;
};

export { RequisitionForm };
