import { FC } from "react";
import get from "lodash/get";
import { Typography, TypographyProps } from "@material-ui/core";
import { Link } from "react-router-dom";

import {
  InjectedFieldProps,
  PublicFieldProps,
  useRecordContext,
  sanitizeFieldRestProps,
  getResourceLinkPath,
} from "react-admin";

export interface ResourceLinkedFieldProps
  extends PublicFieldProps,
    InjectedFieldProps,
    TypographyProps {
  linkedResource: string;
  linkedResourceIdSource: string;
  link?: string;
  resource?: string;
}

const stopPropagation = (e) => e.stopPropagation();

const ResourceLinkedField: FC<ResourceLinkedFieldProps> =
  //memo<ResourceLinkedFieldProps>((props) => {
  (props) => {
    const {
      source,
      emptyText,
      link,
      linkedResource,
      linkedResourceIdSource,
      className,
      resource,
      basePath,
      ...rest
    } = props;
    const record = useRecordContext(props);
    const value = source ? get(record, source) : undefined;
    const resourceLinkPath = !resource
      ? undefined
      : getResourceLinkPath({
          source: linkedResourceIdSource,
          reference: linkedResource,
          resource: resource,
          record,
          basePath,
          link,
        });

    if (!resourceLinkPath) {
      return null;
    }

    return (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeFieldRestProps(rest)}
      >
        <Link
          to={resourceLinkPath as string}
          className={className}
          onClick={stopPropagation}
        >
          {value != null && typeof value !== "string"
            ? JSON.stringify(value)
            : value || emptyText}
        </Link>
      </Typography>
    );
  };

ResourceLinkedField.displayName = "ResourceLinkedField";

ResourceLinkedField.defaultProps = {
  addLabel: true,
  link: "show",
};

export { ResourceLinkedField };
