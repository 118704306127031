import { Admin, Resource, DataProvider } from "react-admin";
import { i18nProvider } from "./i18n/i18nProvider";
import { accountResource, usersResource } from "./lib/modules/accounts";
import {
  LoginPage,
  restAuthProvider,
} from "./lib/core";
import { routes } from "./routes";
import { Layout, Footer } from "./lib/components";
import {
  permissionResource,
  permissionGroupResource,
  roleResource,
  RESOURCE_NAME_ONLY_USER,
} from "./lib/modules";
import { mediaResource } from "./lib/modules/utilities/media";
import {
  itemResource,
  itemsGroupResource,
  itemsSegmentResource,
  itemsClassResource,
} from "./modules/datasetup/items";
import { orgStructureResource } from "./modules/datasetup/org-structure/org-structure";
import {
  requisitionResource,
  RESOURCE_NAME_REQUISITION_DETAILS,
} from "./modules/requisitions";
import { vendorResource } from "./modules/datasetup/vendors";
import {
  enquiryResource,
  RESOURCE_NAME_ENQUIRY_DETAILS,
  RESOURCE_NAME_ENQUIRY_VENDORS,
} from "./modules/enquiries";
import { uomResource } from "./modules/datasetup/unit-of-measure";
import { systemDataResource } from "./modules/datasetup/system-data";
import { Dashboard } from "./modules/dashboard";
import { currencyResource } from "./modules/datasetup/currencies";
import { bidResource } from "./modules/bids";
import { bidsComparisonResource } from "./modules/bidsComparison";
import { businessFunctionResource } from "./modules/datasetup/business-functions";
import { RESOURCE_NAME_COMMENT } from "./modules/comments";
import { locationResource } from "./modules/datasetup/locations";
import { categoryResource } from "./modules/datasetup/category";
import { purchaseGroupResource } from "./modules/datasetup/purchase-groups";
import { vendorGroupResource } from "./modules/datasetup/vendor-group";
import { OrgStructureTypesResource } from "./modules/datasetup/org-structure/org-structure-types";
import { OrgUserGroupsResource } from "./modules/datasetup/org-structure/org-user-groups";
interface AppProps {
  dataProvider: DataProvider;
}

const App = ({ dataProvider }: AppProps) => {
  const fetchResources = (permissions) => {
    const knownResources = [
      <Resource {...usersResource} />,
      <Resource {...accountResource} />,
      <Resource {...permissionGroupResource} />,
      <Resource {...permissionResource} />,
      <Resource {...roleResource} />,
      <Resource {...mediaResource} />,
      <Resource {...itemResource} />,
      <Resource {...itemsSegmentResource} />,
      <Resource {...itemsGroupResource} />,
      <Resource {...itemsClassResource} />,
      <Resource {...orgStructureResource} />,
      <Resource {...uomResource} />,
      <Resource {...currencyResource} />,
      <Resource {...requisitionResource} />,
      <Resource name={RESOURCE_NAME_REQUISITION_DETAILS} />,
      <Resource name={RESOURCE_NAME_ONLY_USER} />, //hack workaround react-admin ReferenceField filter limitation
      <Resource {...enquiryResource} />,
      <Resource {...bidResource} />,
      <Resource {...bidsComparisonResource} />,
      <Resource name={RESOURCE_NAME_ENQUIRY_DETAILS} />,
      <Resource name={RESOURCE_NAME_ENQUIRY_VENDORS} />,
      <Resource {...systemDataResource} />,
      <Resource {...vendorResource} />,
      <Resource name={RESOURCE_NAME_COMMENT} />,
      <Resource {...businessFunctionResource} />,
      <Resource {...locationResource} />,
      <Resource {...purchaseGroupResource} />,
      <Resource {...vendorGroupResource} />,
      <Resource {...OrgStructureTypesResource} />,
      <Resource {...categoryResource} />,
      <Resource {...OrgUserGroupsResource} />,
      <Footer key="footer"/>
    ];

    return knownResources;
  };

  return (
    <Admin
      title="PMS :: Procurement Management System"
      //history={history}
      dataProvider={dataProvider}
      authProvider={restAuthProvider}
      i18nProvider={i18nProvider}
      layout={Layout}
      dashboard={Dashboard}
      loginPage={LoginPage}
      customRoutes={routes}
      disableTelemetry
    >
      {fetchResources}
    </Admin>
  );
};

export default App;
