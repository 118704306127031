import { EditProps } from "react-admin";
import { DefaultEdit } from "../../../../lib/components";
import {
  MediaFileSource,
  transformAndUploadS3Files,
} from "../../../../lib/modules";

import { ItemForm } from "./components/ItemForm";

const ItemEdit = (props: EditProps) => {
  const mediaFileSources: MediaFileSource[] = [
    {
      source: "files",
      subFolder: "items",
    },
  ];
  return (
    <DefaultEdit
      {...props}
      mutationMode="pessimistic"
      transform={transformAndUploadS3Files(mediaFileSources)}
    >
      <ItemForm isEdit={true} {...props} />
    </DefaultEdit>
  );
};

export { ItemEdit };
